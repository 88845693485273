.prompt-modal {
  .action-modal__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    * {
      text-align: center;
      width: 100%;
    }
  }

  .modal-title {
    text-align: center;
  }

  input {
    width: 100%;
  }

  .modal-question {
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .ant-modal-footer {
    display: flex;
    gap: 20px;
  }

  .ant-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }

  .btn-default {
    border: 1px solid rgba(75, 84, 124, 0.25);
  }

  .btn-primary {
    background: $statusError;
    color: $white;
    border: 1px solid $statusError;

    &:disabled {
      background-color: $disabledBgDefault;
      border-color: $disabledBgDefault;
      color: $black;
      opacity: 0.5;
    }
  }

  .btn-primary:hover:not(.btn-primary:disabled, .btn-action:disabled),
  .btn-action:hover:not(.btn-primary:disabled, .btn-action:disabled),
  .btn-primary:focus:not(.btn-primary:disabled, .btn-action:disabled),
  .btn-action:focus:not(.btn-primary:disabled, .btn-action:disabled) {
    background: $statusErrorHover;
    border: 1px solid $statusError;
  }
}
