.notice-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
  top: 50%;
  right: -20px;
  border: 2px solid $statusError;
  &.filled {
    background-color: $statusError;
  }
}
