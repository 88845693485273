.customer-form {
  font-family: $fontPrimary;
  padding: 24px 20% 30px;

  .ant-form-item, .ant-radio-group, .ant-btn, .ant-select, .checkbox-slider {
    font-family: $fontPrimary;

    label {
      font-family: $fontPrimary;
    }

    input {
      font-family: $fontPrimary;
    }
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;

    &-label {
      display: flex;
      gap: 3px;
      padding: 6px 10px;
      width: fit-content;
      background: $grey10;
      border-radius: 11px;
      margin-bottom: 20px;

      div {
        &:nth-child(1) {
          color: rgba(65, 68, 85, 0.70);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }

        &:nth-child(2) {
          color: $black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .customer-main-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    padding: 10px 0 30px 0;
    font-family: $fontPrimary;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: $main;
      height: 70px;
      padding: 12px;
      border-radius: 10px;

      &-label {
        color: rgba(65, 68, 85, 0.70);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      &-value {
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        justify-content: flex-end;
        gap: 10px;
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        border-radius: 10px;
      }

    }

    .verify-kyc-icon-wrap {
      display: flex;
    }

  }

  .customer-base-form {
    border-top: 1px solid $divider;
    padding: 20px 0 10px 0;

    .fields-block {
      width: 45%;
    }

    .main-fields-block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .ant-form-item {
        width: 48.5%;
      }
    }

    .ant-form-item {
      .ant-input-outlined[disabled] {
        border-color: $borderColor;
      }

      .ant-input-disabled {
        background: rgba(0, 0, 0, 0.03);
      }

      .ant-select-disabled {
        .ant-select-selector {
          background: rgba(0, 0, 0, 0.03);
        }

        .ant-select-selector {
          border-color: $borderColor;
        }
      }

      &-label {
       label {
         height: 10px;
         margin-bottom: 10px;
       }
      }

      &-row {
        display: flex;
        flex-direction: column;

        .verify-email-icon-wrap {
          display: flex;
        }
      }

      .ant-form-item-label {
        :after {
          display: none;
        }
      }
    }
  }

  .customer-mobile-verify-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid $divider;
    padding: 30px 0;

    &__item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      row-gap: 17px;

      &-title {
        color: $black;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }

      div {
        &:nth-child(3) {
          width: 100%;
        }
      }
    }

    &__radio-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-radio-group {
        display: flex;
      }

      label {
        display: flex;
        align-items: center;
        height: 38px;
      }
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      min-width: 104px;
      width: 100%;

      .btn {
        min-width: 104px;
        height: 36px;
        justify-content: center;
      }
    }

    .verify-phone-icon-wrap {
      display: flex;
    }
  }

  .customer-tags-form {
    position: relative;
    border-top: 1px solid $divider;
    padding: 20px 0;
    margin-bottom: 100px;

    .ant-form-item {
      .ant-select-disabled {
        .ant-select-selector {
          background: rgba(0, 0, 0, 0.03);
        }
      }

      .ant-select-selector {
        border-color: $borderColor;
      }
    }

    .tags-info-icon-wrap {
      display: flex;
    }

    .fields-block {
      .ant-form-item-row {
        display: flex;
        flex-direction: column;
      }

      .ant-select-selector {
        min-height: 40px;
        padding-inline-start: 8px;
        padding-inline-end: 8px;

        .ant-select-selection-item {
          margin-inline-end: 8px;
          background: $main;

          &-content {
            font-size: 12px;
          }
        }
      }
    }

    .btn {
      position: absolute;
      right: 0;
      bottom: -60px;
      min-width: 104px;
      height: 36px;
      justify-content: center;
    }
  }

  .customer-tfa-form {
    border-top: 1px solid $divider;
    padding: 30px 0;

    &__wrap {
      display: flex;
      justify-content: space-between;
      position: relative;

      &-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .btn {
          min-width: 104px;
          height: 36px;
          justify-content: center;
        }
      }
    }

    &__radio {
      margin-right: 130px;
      &-title {
        font-size: 14px;
        font-weight: 600;
      }
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;
        .ant-radio {
          margin-right: 8px;
        }
      }
    }
  }

  .customer-reg-type-form {
    border-top: 1px solid $divider;
    padding: 30px 0;

    &__wrap {
      display: flex;
      justify-content: space-between;
      position: relative;

      &-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .btn {
          min-width: 104px;
          height: 36px;
          justify-content: center;
        }
      }
    }

    &__radio {
      margin-right: 130px;
      &-title {
        font-size: 14px;
        font-weight: 600;
      }
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;
        .ant-radio {
          margin-right: 8px;
        }
      }
    }
  }
}

.layout .customer-form {
  top: 0;
}
