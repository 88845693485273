.checkbox-slider {
  &-reverse {
    display: flex;
    gap: 8px;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  &__label {
    margin-right: 15px;
    font-weight: 600;
  }

  .anticon-question-circle {
    cursor: help;
    svg {
      fill: rgba(0, 0, 0, 0.45);
    }
  }
}