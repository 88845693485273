.ant-message {
  &-notice-content {
    .ant-message-success,
    .ant-message-error {
      padding: 10px 16px;
      color: $black10;
      white-space: pre-line;
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      text-align: left;
    }
    .ant-message-error {
      background-color: $messageError;
    }
    .ant-message-success {
      background-color: $statusSuccess;
    }
  }
  &-custom-content {
    span {
      display: inline-block;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .ant-message-notice-wrapper {
    .ant-message-notice-content {
      padding: 0;
    }
  }
}
