.services-template {
  display: flex;
  height: 100%;
}

.services-configuration {
  width: 100%;
  padding: 100px 12% 50px;
  margin: 0 auto 5%;

  .checkbox-slider {
    margin-bottom: 24px;
  }

  .ant-form-item-row {
    display: block;

    .checkbox-slider {
      margin-bottom: 0;
    }
  }

  .country-list-select-tab {
    margin-bottom: 24px;
    .ant-select {
      &-selector {
        border-radius: 6px;
      }
    }
  }

  .fingerprint-off {
    margin-bottom: 30px;
  }
  .fingerprint-msg-box {
    display: block;
    color: rgba(65, 68, 85, 0.7);
    margin: 10px 0 20px;
  }
  .timestamp-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin: 20px 0;
    .ant-select {
      .ant-select-arrow {
        position: absolute;
        top: 17px;
      }
    }
    .ant-select,
    .ant-row .ant-input-number {
      width: 200px;
    }
    .ant-select-selector,
    .ant-row .ant-input-number-input {
      height: 40px;
    }
    .ant-select-selection-item {
      line-height: 36px;
    }
    .ant-select-selector,
    .ant-row .ant-input-number {
      border-radius: 6px;
    }
  }

  .chat-support-configurations {
    display: flex;
    flex-direction: column;
    padding: 12px 20px 0px;
    border-radius: 6px;
    background-color: #f2f4f7;

    .ant-divider {
      margin: 12px 0;
    }
  }

  .services-container {
    &__tabs {
      .ant-tabs {
        &-tab {
          &-btn {
            font-family: $fontPrimary;
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
      }
    }
  }
}
