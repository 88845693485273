.page-not-found {
  text-align: center;
  &__top {
    font-size: 32px;
    font-weight: bold;
    display: block;
    color: $secondaryHover;
  }
  &__bottom {
    display: block;
    color: $secondaryHover;
  }
}