.ant-modal {
  &-container,
  &.danger-modal,
  &.warning-modal {
    max-width: 330px;
    border-radius: 6px;
    .ant-modal-content {
      border-radius: 6px;
    }
    .ant-modal-header {
      border-radius: 6px 6px 0 0;
      text-align: center;
      border-bottom: unset;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        margin: 0;
      }
      .modal-question {
        font-size: 18px;
      }
      .modal-warning {
        font-size: 14px;
        color: $blackOpacity70;
      }
      .ant-row {
        margin: 10px 0 0;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      border-top: unset;

      .ant-btn {
        border-radius: 6px;
        width: 80%;
        &.ant-btn-primary {
          border: unset;
        }
      }
    }
    .ant-modal-close {
      display: none;
    }
  }
  &__content-wrap {
    background-color: red;
  }
  &.danger-modal {
    .ant-modal-footer {
      .ant-btn {
        &.ant-btn-primary {
          background-color: $statusError;
          &:hover {
            background-color: $statusErrorHover;
          }
          &:disabled {
            background-color: $disabledBgDefault;
          }
        }
      }
    }
  }
  &-container.preview-modal {
    &.video-preview-modal {
      padding: 0;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      .ant-modal-content {
        width: 100%;
        padding: 0;
      }
      .ant-modal-body {
        padding: 0;
        line-height: 0;
        #video-preview {
          width: 100%;
          border-radius: 6px;
        }
      }
      .ant-modal-footer {
        display: none;
      }
    }
  }
  &-container,
  &.warning-modal {
    .ant-modal-footer {
      .ant-btn {
        &.ant-btn-primary {
          background-color: $primary;
          &:hover {
            background-color: $primary50;
          }
        }
      }
    }
  }
}
