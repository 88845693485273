.table {
  &.table__account-list {
    .ant-table-thead {
      tr > th {
        background-color: $grey10;
        border-bottom: none;
      }
    }
  }
  &.table__customers-list {
    .indicator {
      align-content: center;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        border-radius: 50%;
        background: $indicatorError;
        vertical-align: middle;
      }
      &.enabled {
        &:before {
          background: $indicatorSuccess;
        }
      }
      &.forced {
        &:before {
          padding: 2px;
          margin-left: -1px;
          border: 2px solid $indicatorSuccess;
          background: $transparentColor;
        }
      }
    }
  }

  &.table__malicious-ip-list {
    .ant-table-thead {

      .ant-table-cell {
        white-space: nowrap;
        &-scrollbar {
          display: table-cell !important;
        }
      }

      .ant-table-cell:last-child {
        display: flex;
        justify-content: center;
      }
    }

    .ant-table-cell {
      padding: 10px;
    }

    .ant-table-body {
      overflow-y: auto !important;
      max-height: calc(100vh - 400px) !important;
      min-height: 200px;

      .ant-table-cell:nth-child(1) {
        white-space: nowrap;
      }
    }

    .ant-pagination {
      position: fixed;
      bottom: 0;
      right: 15px;
    }

    table {
      font-family: $fontPrimary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .field-link {
      cursor: pointer;
      color: $secondary;
    }

    .field-icon {
      margin: 0 auto;

      width: fit-content;
      cursor: pointer;
      span {
        display: flex;
      }

      &-disabled {
        cursor: not-allowed;
      }
    }

    &-item {
      .ant-table-thead {
        .ant-table-cell:last-child {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }

  &.editable-table {
    .ant-table.ant-table-bordered {
      > .ant-table-container {
        border: none;

        > .ant-table-header,
        > .ant-table-body {
          > table {
            > thead > tr > th,
            > tbody > tr > td.table-item-number {
              border-right: none;
            }
            > thead > tr > th {
              &.table-item-number {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }
              &.primary-cell {
                border-right: 1px solid $borderColor;
              }
              &:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
            }
          }
        }
      }
    }

    .virtual-grid {
      .virtual-table-cell {
        border: .5px solid $divider;
        box-sizing: border-box;

        &.row-odd {
          background-color: $hoverTableRow;
        }

        &.table-item-number {
          padding: 16px 8px;
          text-align: center;
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .ant-table-thead > tr > th {
      padding: 9px 24px;
      font-size: 12px;
      line-height: 22px;
      font-weight: bold;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.table-item-number {
        padding: 7px 14px;
        text-align: center;
      }

      .head-icon-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    .ant-table-tbody > tr > td {
      height: 0;
      padding: 0;

      &.table-item-number {
        text-align: center;
      }
    }

    .ant-table-body {
      overflow: auto !important;
    }

    .primary-cell {
      font-weight: bold;
    }

    .simple-field {
      padding: 9px 23px;
    }

    .verify-icon-wrapper {
      display: flex;
      margin-left: 10px;
    }
  }

  .table-item-num {
    color: $blackOpacity70;
  }
}
