.ant-menu {

  .ant-btn:active>span,
  .ant-btn:focus>span {
    position: absolute;
  }

  &.ant-menu-inline.menu-container {
    border-inline-end: unset;
  }
}