.calendars-container {
  display: flex;
  height: 100%;

  .calendar-slug {
    margin-top: 40px;
  }

  .calendars-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
    .fields-block {
      .ant-row {
        width: 48%;
      }
    }
    .ant-form-item {
      & > .ant-row {
        display: block;
      }
    }
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
      }
      .ant-select-selector {
        border-radius: 6px;
      }
    }

    .quiz-slug-field {
      margin-top: 40px;
    }

    .text-editor-container {
      margin-bottom: 16px;
    }

    .calendar-template-select {
      .ant-form-item {
        margin-bottom: 4px;
      }
    }
  }

  .fields-flex-block {
    display: flex;
    gap: 24px;
    .ant-form-item {
      width: 100%;
    }

    .date-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .ant-typography {
        height: 40px;
        display: flex;
        align-items: center;
        font-weight: 700;
      }

      &__error {
        color: $statusError;
      }
    }

    .field-button-text {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 16px;
      .ant-form-item {
        margin-bottom: 4px;
      }
    }
  }
}
