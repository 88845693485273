.files-container {
  display: flex;
  height: 100%;

  .files-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .url-container {
    .ant-input {
      padding-right: 40px;
      text-overflow: ellipsis;
    }

    &__notice {
      margin-bottom: 24px;
      color: $grey50;
      font-size: 14px;
      line-height: 22px;
    }

    &__sign {
      margin: 0 8px;
    }
  }
}