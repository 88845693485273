.customers-container {
  margin-right: 16px;

  .customers-header-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 18px;

    &__search-wrapper {
      margin-left: auto;
      width: 100%;
      max-width: 272px;
    }
  }

  @include table-height-100;

  .ant-table-body {
    min-height: calc(100% - 41px);
  }
}
