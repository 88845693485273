.answer-list {
  @include list-dnd-form;

  &__box {
    border: 1px solid $borderColorSecondary;
    border-radius: 6px;
    padding: 25px 18px;

    .answer-form {
      .btn-remove-field {
        position: absolute;
        top: -5px;
        left: 5px;
        visibility: hidden;
        justify-content: center;
        box-shadow: none;
        transition: visibility 0s;
        &:hover {
          border-color: transparent;
        }
      }
      &:hover {
        .btn-remove-field {
          visibility: initial;
        }
      }
      &__row {
        margin-bottom: 10px;
        align-items: center;
      }
      .answer-num {
        display: inline-block;
        color: $blackOpacity70;
      }
      .answer-title {
        display: inline-block;
      }
      .checkbox-slider {
        white-space: nowrap;
        &__label {
          width: 40px;
          margin-right: 5px;
          display: inline-block;
          text-transform: capitalize;
        }
      }
      .btn-action {
        margin: 10px;
        padding: 7px;
        min-width: unset;
      }
    }
  }
}
