.account-form {
  width: auto;
  padding: 100px 25% 50px;
  .ant-form-item-row {
    display: block;
  }
  .account-role-form,
  .account-brand-form {
    position: relative;
    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        &.checkbox-block {
          display: inline-flex;
        }
      }
    }
    &.not-active {
      pointer-events: none;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.55);
      }
    }
    &__select-block {
      margin-bottom: 10px;
    }
  }
  &__title {
    font-weight: bold;
  }
  &__password-notation {
    p {
      max-width: 500px;
      color: $blackOpacity70;
    }
  }
  .account-role-form {
    &__select-block {
      margin-bottom: 10px;
      .radio-group-error {
        color: $statusError;
      }
    }
    .ant-radio-wrapper {
      position: initial;
    }
  }
  &__form-submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    background-color: $primary;
    border: unset;
    border-radius: 6px;
  }
  .warning-alert {
    margin-bottom: 20px;
  }
}
