.terms-container {
  display: flex;
  height: 100%;

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .pages-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .page-section-label {
    margin: 20px 0 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .checkbox-slider {
    margin-bottom: 24px;
  }

  .fields-block {
    .field-label {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      margin-left: 15px;
      font-weight: 600;

      svg {
        margin-left: 6px;
      }
    }

    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      justify-content: flex-start;

      .ant-form-item {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }

    .ant-select-selector {
      border-radius: 6px;
    }

    .copyable-field {
      width: 100%;
    }
  }

  .fields-block,
  .checkbox-slider-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      width: 48%;

      .ant-row {
        display: block;
      }
    }
  }

  .terms-header-block {
    margin-bottom: 20px;

    .btn-action {
      height: 36px;
      background-color: $grey10;
      color: $black;
      font-weight: 600;
      margin: 15px 0 0;
      border-color: transparent;

      svg {
        margin-right: 5px;

        path {
          transition: all 0.3s $bezierTransition;
        }
      }

      &:not([disabled]):hover {
        background-color: $secondary10;
        color: $secondary;

        svg {
          path {
            fill: $secondary;

            &:first-child {
              stroke: $secondary;
            }
          }
        }
      }

      &.generate-stat {
        margin-right: 10px;

        .ant-spin {
          display: flex;
          margin: auto 10px auto 0;
        }

        &:not([disabled]):hover {
          svg path:first-child {
            stroke: none;
          }
        }
      }
    }
  }
}


.terms-statistic-container {
  .terms-header-block {
    margin-bottom: 20px;

    .btn-action {
      height: 36px;
      background-color: $grey10;
      color: $black;
      font-weight: 600;
      margin: 15px 0 0;
      border-color: transparent;

      svg {
        margin-right: 5px;

        path {
          transition: all 0.3s $bezierTransition;
        }
      }

      &:not([disabled]):hover {
        background-color: $secondary10;
        color: $secondary;

        svg {
          path {
            fill: $secondary;

            &:first-child {
              stroke: $secondary;
            }
          }
        }
      }
    }
  }
}