.time-scheduler {
  .date-box {
    margin-bottom: 30px;
  }

  .ant-col {
    display: flex;
    align-items: center;

    .ant-typography {
      min-width: 90px;
    }

    .ant-picker {
      width: 100%;
    }
  }

  .ant-row {
    .ant-picker {
      border-radius: 6px;
      &.datepicker-error {
        border: 1px solid $statusError;
      }
    }
  }
}
