.info-modal {
  z-index: 1040;
  &__container {
    pointer-events: auto;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      padding: 0;
    }
  }

  &__body {
    height: 100%;
    overflow: hidden;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item,
    .ant-form-item-row,
    .ant-form-item-control,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
      height: 100%;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 700px;
    min-height: 200px;
    max-height: calc(100vh - 30px);
    padding: 20px;
    border-radius: 6px;
    background-color: $grey10;
    &-domain {
      height: 365px;
      .info-modal__close-btn {
        display: none;
      }
    }
  }

  &__header {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;

    .info-modal {
      &__subtitle {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 400;
        color: $blackOpacity70;

        .info-messages-block {
          display: flex;
          flex-direction: column;
          span {
            display: inherit;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    cursor: pointer;

    &:hover {
      background: $grey10;
    }
  }

  &__text.ant-input.info-modal__text {
    height: 100%;
    overflow: auto;
    resize: none;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  &__apply-btn,
  &__cancel-btn {
    display: flex;
    justify-content: center;
    width: 32px;
    border: 1px solid $borderColor;
    &-with-text {
      width: inherit;
    }
  }

  &__apply-btn {
    margin-left: auto;
    margin-right: 8px;
    background: $secondary;
    &-with-text {
      &:disabled {
        &:hover {
          background: $btnDisabled;
        }
        path, circle {
          fill: $disabledColor;
        }
    }
      &:not([disabled]) {
        &:hover {
          path, circle {
            fill: $secondary;
          }
        }
        color: $white;
      }

    }

    &:hover {
      background: rgba($secondary, .8);
    }
  }

  &__cancel-btn {
    background: $white;
  }

  &__danger-text {
    color: $statusError;
    cursor: pointer;
  }

  &-action-logs {
    .action-logs {
      &__create-box {
        height: 100%;

        &--raw-text {
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        &--raw-single {
          pre {
            white-space: initial;
          }
        }
      }

      &__create-text {
        flex: 1;
        overflow: auto;

        pre {
          width: fit-content;
        }
      }

      &__update-box {
        display: flex;
        height: 100%;
        justify-content: space-between;
      }

      &__update-content {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;

        &:first-child {
          padding-right: 15px;
          border-right: 1px solid $borderColor;
        }

        &:last-child {
          padding-left: 15px;
        }
      }
    }

    pre {
      flex: 1;
      font-family: $fontPrimary;
    }
  }
}
