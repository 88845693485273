.sidebar {
  overflow: unset;
  height: 100vh;

  .logo-wrapper {
    margin: 20px 0 0 20px;
    position: relative;
    display: flex;
    align-items: center;
  }

  #sso-menu {
    position: relative;

    &:not(:empty) {
        margin-right: 10px;
    }
  }

  .brand-title {
    display: flex;
    align-items: center;
  }

  .brand-select-dropdown {
    &__brand-name {
      .selected-brand-icon {
        color: $white;
        font-size: 13px;
        padding: 2px 5px 2px;
        border-radius: 6px;
        display: inline-block;
        font-weight: bold;
        margin-right: 10px;
        svg {
          margin: -1px 1px;
        }
      }
      .brand-name-label {
        .ant-typography-ellipsis {
          display: inline-block;
          text-align: left;
          width: 140px;
          margin: 0;
        }
      }
    }
    &__btn {
      border: none;
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 40px;
      align-items: center;
      margin: 8px 0;
      padding: 0 25px 0 10px;
      font-weight: 600;
      background-color: transparent;

      &.single-config {
        pointer-events: none;
        .brand-select-dropdown__arrow-down {
          display: none;
        }
      }

      &:focus {
        color: $black;
      }

      &:hover {
        color: $secondary;
        .brand-select-dropdown__arrow-down {
          &:before,
          &:after {
            background-color: $secondary;
          }
        }
      }

      &.ant-dropdown-open {
        background: $borderColor;
        border-radius: 6px;
        color: $black;
        .brand-select-dropdown__arrow-down {
          transform: rotate(180deg);
          transition: none;
          &:before {
            -webkit-transform: rotate(45deg) translateX(2.5px);
            transform: rotate(45deg) translateX(2.5px);
          }
          &:after {
            -webkit-transform: rotate(-45deg) translateX(-2.5px);
            transform: rotate(-45deg) translateX(-2.5px);
          }
          &:before,
          &:after {
            background-color: $blackOpacity85;
          }
        }
      }
    }

    &__arrow-down {
      width: 11px;
      right: 19px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjM1NiA2LjYwNzA3QzQuNzk5OTYgNi40Mzk0MSA1LjA1NzE2IDYuNDI0MTcgNS4yMzg0IDYuNTYxMzRMNS4yOTAzMyA2LjYwNzA3TDkgMTAuMzkxM0wxMi43MDk3IDYuNjA3MDdDMTIuODc0IDYuNDM5NDEgMTMuMTMxMiA2LjQyNDE3IDEzLjMxMjUgNi41NjEzNEwxMy4zNjQ0IDYuNjA3MDdDMTMuNTI4OCA2Ljc3NDczIDEzLjU0MzcgNy4wMzcwOCAxMy40MDkyIDcuMjIxOTZMMTMuMzY0NCA3LjI3NDkzTDkuMzI3MzYgMTEuMzkyOUM5LjE2MyAxMS41NjA2IDguOTA1OCAxMS41NzU4IDguNzI0NTYgMTEuNDM4N0w4LjY3MjY0IDExLjM5MjlMNC42MzU2IDcuMjc0OTNDNC40NTQ4IDcuMDkwNSA0LjQ1NDggNi43OTE0OSA0LjYzNTYgNi42MDcwN1oiIGZpbGw9IiM4Qzk5QjkiIHN0cm9rZT0iIzhDOTlCOSIgc3Ryb2tlLXdpZHRoPSIwLjgiLz4KPC9zdmc+Cg==);
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      transition: none;
    }
  }

  .ant-menu-inline {
    .ant-menu-item,
    .ant-menu-submenu-title {
      margin-inline: 0;
      width: auto;
    }
  }

  .menu-container {
    background: $main;
    padding: 0 12px;

    .ant-menu-item {
      margin-bottom: 0;
      height: 36px;
      line-height: 34px;
      padding-left: 16px !important;
    }

    .ant-menu-submenu {
      padding-bottom: 0 !important;
      .ant-menu-item {
        padding-left: 48px !important;
      }
    }

    .ant-menu-submenu-title {
      padding-left: 16px !important;
      height: 36px;
      line-height: 34px;
      margin-bottom: 2px;
      margin-top: 2px;
    }

    .ant-menu-submenu-arrow {
      width: 11px;
      right: 19px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjM1NiA2LjYwNzA3QzQuNzk5OTYgNi40Mzk0MSA1LjA1NzE2IDYuNDI0MTcgNS4yMzg0IDYuNTYxMzRMNS4yOTAzMyA2LjYwNzA3TDkgMTAuMzkxM0wxMi43MDk3IDYuNjA3MDdDMTIuODc0IDYuNDM5NDEgMTMuMTMxMiA2LjQyNDE3IDEzLjMxMjUgNi41NjEzNEwxMy4zNjQ0IDYuNjA3MDdDMTMuNTI4OCA2Ljc3NDczIDEzLjU0MzcgNy4wMzcwOCAxMy40MDkyIDcuMjIxOTZMMTMuMzY0NCA3LjI3NDkzTDkuMzI3MzYgMTEuMzkyOUM5LjE2MyAxMS41NjA2IDguOTA1OCAxMS41NzU4IDguNzI0NTYgMTEuNDM4N0w4LjY3MjY0IDExLjM5MjlMNC42MzU2IDcuMjc0OTNDNC40NTQ4IDcuMDkwNSA0LjQ1NDggNi43OTE0OSA0LjYzNTYgNi42MDcwN1oiIGZpbGw9IiM4Qzk5QjkiIHN0cm9rZT0iIzhDOTlCOSIgc3Ryb2tlLXdpZHRoPSIwLjgiLz4KPC9zdmc+Cg==);
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      transition: none;
    }

    .ant-menu-submenu-arrow:after, .ant-menu-submenu-arrow:before {
      content: none;
    }

    .loader-wrapper {
      min-height: 35px;
      margin: 16px 20px 8px;
      position: relative;
      .ant-spin.ant-spin-spinning {
        position: absolute;
      }
    }
  }

  .ant-menu {
    .ant-menu-sub.ant-menu-inline {
      background: $main;
    }
    .ant-menu-submenu-open {
      position: relative;
      .ant-menu-submenu-title {
        background: $borderColor;
        color: $black;
        border-radius: 6px;
        .ant-menu-submenu-arrow {
          -webkit-transform: translateY(-18px);
          transform: translateY(-18px) rotate(180deg);
        }
        &:hover {
          color: $black;
        }
      }
    }
    &-item {
      &.dropdown__nav-menu {
        height: 40px;
        margin: 8px 0;
        padding: 0 !important;
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item {
      border-radius: 6px;
    }
    .ant-menu-item-selected,
    .ant-menu-item-active:active {
      color: $white;
      background-color: $secondary;
      a {
        color: $white;
      }
    }
  }
}
