.ant-switch {
  min-width: 36px;
  background-color: $blackOpacity25;
  .ant-switch-handle {
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
  }
}
.ant-switch-checked {
  background-color: $secondary;
  .ant-switch-handle {
    left: calc(100% - 18px);
  }
}
