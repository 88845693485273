.tournaments-container {
  display: flex;
  height: 100%;

  .tournament-platform-select {
    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .tournaments-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .fields-block {
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      .ant-row {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }
  }

  .fields-block,
  .checkbox-slider-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      width: 48%;

      .ant-row {
        display: block;
      }
    }
  }
}