.filter-select {
  width: 100%;

  &-wrapper {
    padding-right: 13px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    padding: 3px 30px 3px 14px;
    border-radius: 6px;
    letter-spacing: 0.4px;
  }

  &.ant-select {
    .ant-select-selector {
      background: $grey10;
    }
  }

  .ant-select-arrow {
    right: 15px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    .item-icon img {
      margin-left: 0;
    }

    &:has(.item-icon) {
      &::before {
        display: none;
      }
    }

    &::before {
      content: "Filter:";
      display: inline-block;
      margin-right: 5px;
      padding-left: 32px;
      background: transparent
        url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjEyNSA1LjA2MjVDMS4xMjUgNC42NTQ3NiAxLjQ1NTU0IDQuMzI0MjIgMS44NjMyOCA0LjMyNDIySDE2LjEzNjdDMTYuNTQ0NSA0LjMyNDIyIDE2Ljg3NSA0LjY1NDc2IDE2Ljg3NSA1LjA2MjVDMTYuODc1IDUuNDcwMjQgMTYuNTQ0NSA1LjgwMDc4IDE2LjEzNjcgNS44MDA3OEgxLjg2MzI4QzEuNDU1NTQgNS44MDA3OCAxLjEyNSA1LjQ3MDI0IDEuMTI1IDUuMDYyNVpNMi4xNzQ5OSA5QzIuMTc0OTkgOC41OTIyNiAyLjUwNTUzIDguMjYxNzIgMi45MTMyNyA4LjI2MTcySDE1LjA4NjdDMTUuNDk0NCA4LjI2MTcyIDE1LjgyNSA4LjU5MjI2IDE1LjgyNSA5QzE1LjgyNSA5LjQwNzc0IDE1LjQ5NDQgOS43MzgyOCAxNS4wODY3IDkuNzM4MjhIMi45MTMyN0MyLjUwNTUzIDkuNzM4MjggMi4xNzQ5OSA5LjQwNzc0IDIuMTc0OTkgOVpNNS4wNTYyNCAxMi4xOTkyQzQuNjQ4NSAxMi4xOTkyIDQuMzE3OTYgMTIuNTI5OCA0LjMxNzk2IDEyLjkzNzVDNC4zMTc5NiAxMy4zNDUyIDQuNjQ4NSAxMy42NzU4IDUuMDU2MjQgMTMuNjc1OEgxMi45NDM4QzEzLjM1MTYgMTMuNjc1OCAxMy42ODIxIDEzLjM0NTIgMTMuNjgyMSAxMi45Mzc1QzEzLjY4MjEgMTIuNTI5OCAxMy4zNTE2IDEyLjE5OTIgMTIuOTQzOCAxMi4xOTkySDUuMDU2MjRaIiBmaWxsPSIjOEM5OUI5Ii8+Cjwvc3ZnPgo=")
        0 center no-repeat;
      background-size: 20px;
      color: $blackOpacity70;
    }
  }

  &__dropdown-wrapper {
    border-radius: 6px;
    padding: 0;

    &
      .ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    &
      .ant-select-dropdown
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $secondaryHover;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }

    .ant-select-item {
      padding: 9px 14px;
      border-radius: unset;
    }

    .ant-select-item-empty {
      padding: 0;
    }

    .ant-select-item-option-content{
      text-transform: capitalize;
    }

    .ant-select {
      &-item {
        padding: 9px 14px;

        .item-icon img {
          margin-left: 0;
        }

        &-option {
          &-active:not(.ant-select-item-option-selected) {
            background-color: transparent !important;
            color: $secondary;
          }

          &-content {
            display: flex;
            align-items: center;
          }

          &-state {
            position: absolute;
            margin-right: 10px;
          }

          &-selected {
            color: $secondary;
            font-weight: 400;
            background-color: $white;
          }
        }
      }
    }

    .has-divider {
      border-bottom: 1px solid $divider;
    }

    .filter-select__checkbox-wrapper {
      padding: 9px 14px;
    }

    .checkbox-slider {
      display: flex;
      justify-content: space-between;

      &__label {
        font-weight: 400;
      }
    }

    .filter-actions {
      display: flex;
      align-items: center;
      padding: 9px 14px;
      gap: 12px;

      input {
        outline: none;
        border: none;
        box-shadow: none !important;
      }

      svg {
        cursor: pointer;
      }
    }

    .filter-clear {
      cursor: pointer;
    }

    .filter-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 9px 14px;
      color: $black;
      cursor: pointer;

      svg:last-child {
        margin-left: auto;
        transform: rotate(270deg);
      }
    }

    .filter-item__active {
      color: $black;
      font-weight: 600;
      svg{
        path{
          fill: $secondary;
        }
      }
    }
  }

  .filter-count {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $blackOpacity70;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
  }
}
