.page-menu-list {
  top: -75px;
  display: flex;
  flex-direction: column;
  max-width: 256px;
  width: 100%;
  height: 100vh;
  padding-bottom: 10px;
  position: relative;

  .ant-menu {
    .ant-menu-item-selected {
      .ant-typography {
        color: $secondary;
      }
    }
  }

  &__header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    span {
      font-size: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 195px;
    }

    .button-group {
      display: flex;
      margin-right: 10px;

      .btn-add,
      .button-upload {
        outline: none;
        border: none;
        box-shadow: none;
        display: flex;
        width: auto;
        height: auto;
        padding: 0;
        justify-content: center;
      }

      .btn-add {
        margin-right: 5px;
      }

      .button-upload {
        transition: none;

        path:not(:last-child) {
          fill: $secondary;
          stroke: $secondary;
        }

        path:last-child {
          fill: $secondary;
        }

        &.gap-right {
          margin-right: 10px;
        }
      }
    }

    .btn-add {
      margin-right: 15px;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    min-height: 100vh;
    height: calc(100% + 96px);
    top: -21px;
    width: 1px;
    background-color: $divider;
  }

  &--normal {
    top: -30px;
    padding-bottom: 55px;

    &:after {
      top: -96px;
    }
  }

  .menu-container,
  .skeleton-loader {
    padding: 0 12px 5px 0;
  }

  .menu-container {
    flex-grow: 1;
    overflow-y: auto;
  }

  .ant-menu-item {
    position: relative;
    display: flex;
    width: auto;
    padding-left: 14px !important;
    padding-right: 45px !important;
    padding-right: 4px;
    border-radius: 6px;
    height: 36px !important;
    margin-inline: 0;

    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }

    .item-icon {
      margin-right: 10px;
    }

    .menu-item-text {
      flex: 1;
      overflow: hidden;

      &.inactive-item {
        .ant-typography {
          color: $blackOpacity35;
        }
      }
    }

    .ant-typography {
      margin: 0;
      line-height: inherit;
    }

    &:after {
      content: unset;
    }
    .menu-item-version {
      align-items: center;
      color: $grey50;
      display: flex;
      gap: 4px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .menu-item-badges {
      align-items: center;
      display: flex;
      gap: 4px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      .schedule-set {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid $statusError;

        &.schedule-enabled {
          background-color: $statusError;
        }
      }

      .display-condition-error {
        align-self: center;
        margin-top: 6px;
      }

      .icon-days-managing {
        align-self: center;
        margin-top: 10px;
        margin-right: 5px;
      }
    }
  }

  &__link-wrapper {
    padding: 0 10px;
  }

  &__link {
    color: $secondary;
    cursor: pointer;

    &:hover {
      color: $secondaryHover;
    }

    &-disabled {
      cursor: not-allowed;
      opacity: .5;

      &:hover {
        color: $secondary;
      }
    }
  }

  &__filter-select {
    margin-top: 5px;

    .filter-select-wrapper {
      .ant-select-arrow {
        top: 50%;
        height: 18px;
        margin-top: 0;
        transform: translateY(-50%);
      }

      .ant-select-selector {
        border: none;
      }
    }
  }

  &__filter-input {
    margin: 10px 0;
    padding-right: 13px;
  }
}
