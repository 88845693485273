.pages-container {
  display: flex;
  height: 100%;

  .pages-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .page-section-label {
    margin: 20px 0 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .checkbox-slider {
    margin-bottom: 24px;
  }

  .fields-block {
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      .ant-form-item {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }

    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .fields-block,
  .checkbox-slider-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item,
    .checkbox-slider {
      width: 48%;
    }

    .ant-row,
    .checkbox-slider {
      display: block;
    }
  }

  .images-container {
    width: 100%;
    margin-top: 24px;

    .image-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .text-label__subtitle {
      margin: 0 0 10px;
    }
  }
}

.conditions-dropdown {
  .ant-select-item-option {
    &:first-child {
      border-bottom: 1px solid $divider;
    }
  }
}