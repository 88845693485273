.question-list {
  @include list-dnd-form;

  &__remove-button {
    height: auto;
    padding: 0;
    color: $red;

    &:hover {
      color: $red;
    }
  }

  &__cta-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px;
  }
}
