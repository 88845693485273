@mixin list-dnd-form {
  margin-top: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }

  &__droppable-mode-toggle {
    height: auto;
    padding: 0;
  }

  &__add-button {
    padding: 0 10px;

    svg {
      margin-right: 10px;
    }
  }

  .droppable-list-container {
    .droppable-list__item-container {
      height: 46px;
    }
  }

  .ant-menu {
    background-color: transparent;
  }

  .ant-menu-item {
    margin: 0 0 8px;
    background-color: $borderColor;
    height: 46px;
    border-radius: 6px;
  }

  .ant-collapse-item {
    margin-bottom: 8px;
    border-radius: 6px !important;
    overflow: hidden;
  }

  .ant-collapse-header {
    height: 46px;
    background-color: $borderColor;

    svg {
      top: 18px !important;
      left: 18px !important;
      padding: 0 !important;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    path {
      fill: $grey50;
    }
  }

  .ant-collapse-item-active .ant-collapse-header {
    svg {
      transform: rotate(90deg);
    }
  }

  .ant-collapse-content {
    background-color: $white !important;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}


.content-list {
  @include list-dnd-form;

  &__remove-button {
    display: block;
    height: auto;
    padding: 0;
    margin: 14px 0 0 auto;
    color: $red;

    &:hover {
      color: $red;
    }
  }
}
