.translations-container {
  margin-right: 16px;

  @include table-height-100;

  .ant-table-body {
    min-height: calc(100% - 41px);
    padding-bottom: 25px;

    .primary-cell {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .translation-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 18px;

    &__controls {
      display: flex;
    }

    &__translate-add {
      display: inline-block;
      color: $secondary;

      .popover-editor__editable-field {
        display: inline-block;
        width: auto;
        min-height: auto;
        border: none;
        padding: 0 16px;

        > span {
          display: inline-block;
          border-bottom: none;
          vertical-align: middle;

          &:first-child {
            margin-right: 8px;
            font-size: 18px;
          }
        }
      }
    }

    &__dropdown {
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 20px;
        background: $borderColor;
        position: relative;
      }

      svg {
        margin-left: 8px;
        transition: all .3s $bezierTransition;
      }

      .ant-dropdown-trigger {
        padding: 0 16px;
        cursor: pointer;
      }

      .ant-dropdown-open {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__search-wrapper {
      width: 100%;
      max-width: 272px;
    }

    &__btn-wrapper {
      .ant-btn {
        height: 36px;
        margin-left: 8px;
        background-color: $grey10;
        color: #000;
        font-weight: 600;
        border: none;

        &:first-child {
          margin-left: 0;
        }

        &:not([disabled]):hover {
          background-color: $secondary10;
          color: $secondary;

          svg {
            path {
              fill: $secondary;

              &:first-child {
                stroke: $secondary;
              }
            }
          }
        }
      }

      svg {
        margin-right: 8px;

        path {
          transition: all 0.3s $bezierTransition;
        }
      }
    }
  }
}

.translation-statistic {
  overflow: hidden;

  .statistic {
    &-wrapper {
      width: 184px;
      padding: 0;
      min-width: unset;
      background: $white;
      .ant-dropdown-menu-item {
        padding: 0;
        border-bottom: 1px solid $borderColor;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;

      .ant-progress-text {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    
    &-text {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      &--grey {
        color: $grey50;
      }
    }
  }
}

.translation-upload-tooltip {
  max-width: 350px;
  border-radius: 6px;

  .ant-tooltip-inner {
    padding: 5px 25px;
    border-radius: 6px;
    text-align: center;
  }
}
