.section-display-condition-container {
  margin-bottom: 24px;

  .ant-collapse,
  .ant-collapse-content {
    background-color: $main;
  }

  .display-condition-label {
    display: flex;
    align-items: center;
    gap: 8px;

    &__note {
      color: $secondary;

      &--restricted {
        color: $red;
      }
    }
  }

  .multiple-select-tag {
    align-self: center;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    flex: none;
    font-size: 14px;
    height: 26px;
    line-height: 24px;
    margin-bottom: 2px;
    margin-inline-end: 4px;
    margin-top: 2px;
    max-width: 100%;
    padding-inline-end: 4px;
    padding-inline-start: 8px;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;

    &--error {
      background-color: $whiteLightRedColor;
    }
  }

  .display-condition-divider {
    margin: 0 0 16px;

    &::before {
      content: none;
    }

    .ant-divider-inner-text {
      color: $black20;
      font-weight: 400;
      font-size: 12px;
      padding-left: 0;
      opacity: 0.7;
    }
  }

  .custom-filters {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .fields-label {
      font-size: 14px;
      font-weight: bold;
      color: $black;
    }

    .description-content {
      display: flex;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: unset;
        width: 48%;
      }
    }
  }

  .white-block {
    width: 100%;
    background-color: $white;
    border: 1px solid $borderColor;
    border-radius: 6px;
    padding: 16px 16px 0;
  }
}
