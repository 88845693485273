.accounts-container {
  margin-right: 15px;
  .search-filter {
    position: absolute;
    top: 20px;
    right: 365px;
    .filter-input {
      max-width: 276px;
    }
  }
  .ant-table-wrapper {
    .ant-table-body {
      overflow: auto !important;
    }
  }
  .ant-table-row > td > .account-actions {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      border: none;
      border-radius: 6px;
      box-shadow: none;
      line-height: 0;
    }

  }
  .ant-table-row {
    &.account-blocked > td:not(:first-child) {
      color: $statusError;
      .account-actions > button:first-child {
        background-color: $statusError;

        &:hover {
          background-color: $statusErrorHover;
        }

        svg > path {
          fill: $white;
        }
      }
    }
    &:hover > td > .account-actions > .ant-btn {
      background-color: $hoverTableRow;
    }
    td > .account-actions {
      .account-actions__block,
      .account-actions__edit {
        &:active,
        &:hover {
          border-radius: 6px;
          background-color: $secondary10;
        }
      }
      .account-actions__delete {
        &:active,
        &:hover {
          border-radius: 6px;
          background-color: $removeColor;
        }
      }
    }
  }
}