.seo-container {
  .seo-form-wrapper {
    padding-top: 8px;
  }

  .main-inputs-group {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .section-description-container {
    margin-bottom: 16px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      padding: 16px 24px 24px;
    }
  }
}
