.categories-container {
  display: flex;
  height: 100%;

  .categories-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;

    .order-category-games {
      margin: 10px 0;
      padding-left: 5px;
    }

    .fields-block {
      display: flex;
      justify-content: space-between;

      .ant-form-item {
        width: 48%;

        >.ant-row {
          display: block;
        }
      }

      .ant-select-selector {
        border-radius: 6px;
      }

      &.full-width {
        .ant-row {
          width: 100%;
        }
      }
    }

    .row-justify-wrapper {
      justify-content: space-between;
      flex-flow: nowrap;
      gap: 20px;

      .ant-input-number {
        width: 100%;
        border-radius: 5px;
      }
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: unset !important;
      margin: 0 5px;
      position: relative;
    }

    .checkbox-slider {
      display: flex;
      align-items: center;

      &__label {
        display: inline-flex;
        align-items: center;
        height: 32px;
      }
    }

    .items-after-zing {
      padding-top: 20px;
      overflow: initial;
    }

    .zing-enabled-pipe {
      overflow: visible;
      border-top: 1px solid $secondary;
      margin-top: 27px;
      padding-top: 5px;
    }

    .ffp-tooltip,
    .zing-games-tooltip {
      position: absolute;
      z-index: 11;
      font-size: 10px;
      top: -11px;
      right: 15px;
      font-weight: 500;
      border-radius: 6px;
      padding: 12px 10px;
    }

    .ffp-tooltip {
      color: $red;
      background: $whiteLightRedColor;
    }

    .zing-games-tooltip {
      color: $secondary;
      background: $secondary50;
    }

    .highlight-fixed-position {
      border-left: 1px solid $red;
      border-right: 1px solid $red;
      overflow: hidden;

      &-first,
      &-one {
        overflow: unset;
        border-top: 1px solid $red;
      }

      &-first {
        border-radius: 11px 11px 0 0;
      }

      &-last {
        border-bottom: 1px solid $red;
        border-radius: 0 0 11px 11px;
      }

      &-one {
        border-radius: 11px;
      }
    }

    .games-checkbox-container {
      .ant-checkbox-group {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .provider-name-with-pipe {
      position: relative;
      color: $grey;
    }

    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
      }
    }

    @import "../forms/content/game-form-lists";
    @import "../forms/content/entities-selection";
  }

  &__tabs {
    overflow: unset;
    margin-top: 10px;

    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .images-container {
    .image-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}