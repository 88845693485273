.images-pack-container {
  display: flex;
  height: 100%;

  .images-pack-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .images-pack-section-label {
    margin: 20px 0 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .url-container {
    margin-bottom: 16px;

    .ant-form-item {
      margin-bottom: 4px;
    }

    &__notice {
      display: flex;
      color: $blackOpacity70;
      font-size: 12px;
      line-height: 1.5;
    }

    &__icon-wrapper {
      margin-right: 8px;
    }
  }

  .fields-block {
    display: flex;
    justify-content: space-between;

    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    .ant-form-item,
    .checkbox-slider {
      width: 48%;
    }

    .ant-row,
    .checkbox-slider {
      display: block;
    }

    &.full-width {

      .ant-form-item,
      .country-list-select-tab {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }

    .ant-select-selector {
      border-radius: 6px;
    }
  }

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }
}