.ant-page-header {
  .ant-page-header-heading-extra {
    .ant-btn-primary {
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &>button:first-child {
      margin-right: 16px;
    }
  }
  .ant-page-header-heading-title {
    font-size: 24px;
    font-weight: 700;
  }
  &-back {
    .history-go-back {
      display: flex;
      font-size: 14px;
      text-transform: initial;
      gap: 8px;
    }
  }
  &-heading {
    text-transform: capitalize;
    &-extra {
      display: flex;
      align-items: center;
      right: 15px;
      top: 15px;

      .ant-divider-vertical {
        height: 16px;
        margin: 0 16px;
      }

      .ant-btn {
        margin: 0;
      }
    }

    &-left {
      left: 20px;
      top: 15px;
    }

    &-extra,
    &-left {
      position: absolute;
      z-index: 1;
    }
  }
  .header-dropdown-menu {
    border: none;
    box-shadow: none;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 4px 10px;
    margin-left: -10px;

    &__user-logo {
      height: 20px;
    }
    &__arrow-down {
      height: 6px;
      top: -2px;
      position: relative;
      transform: rotate(0deg);
    }
    &.ant-dropdown-open {
      background: $secondary10;

      .header-dropdown-menu__arrow-down {
        top: 17px;
        transform: rotate(180deg);
      }
    }
  }

  &-back-button {
    margin-top: 10px;
  }
}
