.button-settings {
  padding-top: 24px;
  &__main-info {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      margin-bottom: 4px;
    }
  }
  &__fields-block {
    position: relative;
    width: 48%;
    margin-bottom: 24px;
  }
  &__notice {
    display: flex;
    color: $blackOpacity70;
    font-size: 12px;
    line-height: 1.5;
    &.top-margin {
      margin-top: 5px;
    }
    &-msg {
      display: inline-block;
      white-space: pre-line;
    }
  }
  &__icon-wrapper {
    margin: 1px 7px 0 0;
  }
  &__color-picker {
    position: absolute;
    top: 50px;
    left: 10px;
    z-index: 2;
  }
  &__color-input {
    padding-left: 35px;
  }
}
