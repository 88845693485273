.games-container {
  display: flex;
  height: 100%;

  .page-menu-list {
    .ant-menu-item {
      width: calc(100% - 8px) !important;
      margin-block-start: 2px;
    }
  }

  .games-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;

    .provider-select {
      &__img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }

    .ant-spin {
      margin: 8px 0 9px;
    }

    .checkbox-slider {
      margin: 10px 0;
    }

    .new-games {
      &-category {
        padding-bottom: 30px;

        &__label {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          height: 32px;
        }

        &__wrap {
          width: 48%;

          &:hover .ant-picker-clear {
            opacity: 1;
          }

          .ant-picker {
            width: 100%;
            padding: 8px 11px 8px;
            border-radius: 6px;

            .ant-picker-clear {
              right: -32px;

              & svg {
                fill: $red;
              }
            }
          }
        }
      }

      &-badge-container {
        display: flex;
        justify-content: space-between;

        .ant-col-12 {
          max-width: 48%;

          .badge-field {

            .ant-picker,
            .ant-select-selector {
              height: 40px;

              .ant-select-selection-item {
                line-height: 38px;
              }
            }
          }
        }
      }
    }

    .game-badge-label {
      display: block;
      font-size: 14px;
      font-weight: bold;
    }

    .fields-block {
      .badge-field {
        width: 48%;
      }

      .ant-row {
        .ant-input-group-addon {
          border-radius: 6px 0 0 6px;
          font-weight: 600;
        }
      }

      &.full-width {
        .ant-form-item {
          width: 100%;
        }
      }
    }

    .ant-select-selector,
    .ant-input-number {
      border-radius: 6px;
    }

    .ant-input-number {
      width: 100%;

      .ant-input-number-handler-wrap {
        border-radius: 0 6px 6px 0;
      }
    }

    .fields-block,
    .checkbox-slider-block {
      display: flex;
      justify-content: space-between;

      .ant-form-item,
      .checkbox-slider {
        width: 48%;
      }

      .ant-row,
      .checkbox-slider {
        display: block;
      }

      .demo-mode-checkbox {
        display: flex;
        width: 50%;
      }
    }

    .categories-container {
      .ant-checkbox-group-item {
        display: block;
      }
    }

    @import "../forms/content/entities-selection";
  }

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .images-container {
    .image-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}