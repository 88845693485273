.text-label {
  &__title {
    font-size: 18px;
  }
  &__subtitle {
    font-size: 14px;
  }

  &__title,
  &__subtitle {
    margin-bottom: 10px;
    font-weight: bold;
  }
}
