.btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

@mixin btn-color-palettes($textColor: $main, $bgColor: $default) {
  color: $textColor;
  background: $bgColor;
  border-color: $bgColor;
  @content;
}

@mixin btn-effects($hoverBg, $hoverBorder, $activeBg, $activeBorder) {
  &:hover:not(&:disabled) {
    background: $hoverBg;
    border-color: $hoverBorder;
  }

  :is(&:focus, &:active):not(&:disabled) {
    background: $activeBg;
    border-color: $activeBorder;
  }

  @content;
}

.btn-primary,
.btn-action {
  @extend .btn;
  @include btn-color-palettes($white, $primary);
  @include btn-effects($primary50, $primary50, $primary50, $primary50);
  min-width: 89px;

  &:disabled {
    color: $disabledColor;
    border-color: $disabledBorder;
    background: $disabledBg;

    svg {
      path, circle {
        fill: $disabledColor;
      }
    }
  }
}

.btn-secondary {
  @extend .btn;
  @include btn-color-palettes($white, $secondary);
  @include btn-effects($secondaryHover, $secondaryHover, $secondaryHover, $secondaryHover);
  border-color: $transparentColor;
  :is(&:focus,&:active,&:hover):not(&:disabled) {
    color: $white;
  }
}

.btn-transparent-bordered {
  @extend .btn;
  @include btn-color-palettes($black, $transparentColor);
  @include btn-effects($secondary, $secondary, $secondaryHover, $secondaryHover);
  border-color: $blackOpacity35;
  :is(&:focus,&:active,&:hover):not(&:disabled) {
    color: $white;
  }
}

.btn-add-field {
  color: $secondary;
  box-shadow: none;
}

.btn-group {
  display: inline-flex;
  justify-content: space-between;
  .btn:not(:first-child) {
    margin: 0 20px;
  }
}

.btn-group {
  .ant-btn-default.btn-loader {
    margin: 0 10px;
    outline: none;
    border: none;
    box-shadow: none;
    svg {
      transform: scale(.8);
    }
  }
}

.btn-switcher {
  color: $black;
  box-shadow: none;
  font-weight: normal;
  border: none;
  background: transparent;
  &.btn-active {
    color: $secondary;
    background: $white;
    &:hover {
      background: $white;
    }
  }
  &:hover {
    background: transparent;
  }
}

.change-btn,
.download-btn,
.delete-btn {
  display: flex;
  justify-content: center;
  border: unset;

  &:hover {
    border: unset;
  }
}


//.change-btn,
//.download-btn {
//  &:hover {
//    svg path {
//      fill: $secondary;
//    }
//  }
//}

.expand-btn {
  display: flex;
  justify-content: center;
  border: unset;
}

.btn-open-modal {
  border: 1px solid $blackOpacity35;
  margin-bottom: 5px;
}

.ant-btn {
  &-default {
    box-shadow: none;
  }
}

.btn-days-managing {
  padding: 5px 16px;
  border-radius: 6px;
  font-weight: 600;
  color: $black;
  background: $grey10;

  svg path {
    fill: $black;
  }

  &:hover {
    svg path {
      fill: $secondary;
    }
  }
}
