.iovation-form {
  .ant-form-item {
    margin-bottom: 0;
    .ant-input-number {
      border-radius: 6px;
    }
  }
  .customer-events-count {
    .checkbox-slider {
      transform: scale(.8);
    }
  }
  .events-statistic {
    &__header {
      display: flex;
      justify-content: space-between;
    }
    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid $borderColor;
      border-radius: 6px;
      padding: 16px 20px;
      margin-bottom: 30px;
      :where(.events-statistic-left,.events-statistic-right) > div {
        padding: 8px 0;
        display: block;
      }
    }
    .stat-pagination {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;
      .prev,
      .next {
        cursor: pointer;
        &__label {
          margin: 0 10px;
          color: $secondary;
        }
        &:hover:not(.disabled) {
          .prev, .next {
            &__arrow {
              color: $secondary;
            }
          }
        }
        &__arrow {
          color: $grey50;
          font-size: 12px;
        }
        &.disabled {
          cursor: not-allowed;
          .prev, .next {
            &__label {
              color: $grey;
            }
          }
        }
      }
    }
  }
}
