.payments-container,
.payments-categories-container {
  display: flex;
  height: 100%;

  .payments-configuration-section,
  .payments-categories-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;

    @import "../forms/content/entities-selection";

    .info-text {
      color: $blackOpacity70;
      margin: 8px 0 14px;
      display: block;
    }
  }

  .payments-categories-configuration-section {
    .fields-block:first-child {
      .ant-row:last-child {
        label {
          visibility: hidden;
        }
      }
    }

    .payment-category-methods-form {
      .selected-methods-info {
        margin-top: 10px;
        font-size: 12px;
        color: $blackOpacity70;
      }

      .checkbox-deposit-type:first-child,
      :not(.checkbox-withdraw-type) + .checkbox-withdraw-type {
        position: relative;
        margin-top: 20px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          height: 1px;
          width: calc(100% + 32px);
          z-index: 0;
        }

        &:before {
          font-size: 11px;
          position: absolute;
          padding: 3px 10px;
          border-radius: 6px;
          top: -20px;
          right: -15px;
          z-index: 1;
        }
      }

      .checkbox-deposit-type:first-child {
        &:after {
          top: -10px;
          background-color: $green;
        }

        &:before {
          content: "DEPOSIT";
          color: $green;
          background-color: $lightGreen;
        }
      }

      :not(.checkbox-withdraw-type) + .checkbox-withdraw-type {
        &:after {
          top: -15px;
          background-color: $secondary;
        }

        &:before {
          content: "WITHDRAWAL";
          color: $secondary;
          background-color: $secondary50;
          top: -25px;
        }
      }

      .filter-input {
        width: 100%;
        max-width: unset;
      }

      .empty-block {
        transform: translateY(15px);
      }

      .ant-checkbox-group {
        width: 100%;

        .ant-checkbox-wrapper {
          width: inherit;
        }
      }

      .ant-checkbox {
        top: 0;
      }

      .deposit-type-label,
      .withdraw-type-label {
        margin: 0 10px 0 5px;
        border-radius: 6px;
        font-size: 11px;
      }

      .deposit-type-label {
        color: $green;
        background-color: $lightGreen;
        padding: 4px 18px;
      }

      .withdraw-type-label {
        color: $secondary;
        background-color: $secondary50;
        padding: 4px 10px;
      }
    }

    .images-container {
      .image-section {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }

  .fields-block {
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      .ant-form-item {
        width: 100%;
      }

      .ant-form-item-control-input-content input {
        max-width: none;
      }

      .image-uploader {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }

    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .anonymous-accepted-slider{
    margin-top: 24px;
  }

  .fields-block,
  .checkbox-slider-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item,
    .checkbox-slider {
      width: 48%;
    }

    .ant-row,
    .checkbox-slider {
      display: block;
    }
  }

  .payments-form {
    &__textarea {
      min-height: 173px;
      resize: none;
      font-family: $fontMonospaced;
      font-size: 13px;
    }
  }

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
          align-items: center;
        }
      }
    }
  }

  .payment-method {
    &__payment-form {
      .section-description-container {
        margin-bottom: 16px;
      }

      .image-uploader {
        .text-label__subtitle {
          margin: 0 0 10px;
        }
      }
    }

    &__position-form {

      .search-result-field,
      .order-brand-methods {
        background-color: transparent;
      }

      .droppable-list-container {
        max-height: 560px;
      }

      .search-result-field {
        &.field-active {
          background-color: $white;
        }
      }

      .section-description-container {
        .ant-tabs-content-holder {
          padding: 16px 24px 8px;
          min-height: 200px;
        }
      }
    }
  }

  .payment-inputs {
    position: relative;
    margin-bottom: 20px;
    flex-direction: column;

    .ant-tabs {
      &-nav {
        margin: 0 0 8px 0;
        &:before {
          content: none;
        }
      }
      &-tab {
        padding: 10px 10px 10px 6px;
      }
    }

    .expand-btn {
      top: 75px !important;
      right: 2px !important;
    }

    .text-label {
      &__subtitle {
        margin-bottom: 0;
        &:after {
          content: ":";
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .expand-btn {
      position: absolute;
      right: 5px;
      top: 45px;
    }
  }

  .restriction-block {
    flex-direction: column;

    &__hint {
      color: $blackOpacity70;
    }
  }
}
