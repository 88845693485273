.ant-action-modal {
  &.warning-modal,
  &.danger-modal {
    .modal-title {
      text-align: center;
      line-height: 1;
    }

    .ant-modal-body {
      padding: 24px;

      .modal-question {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
      }
    }

    .ant-modal-footer {
      padding: 20px 0 0;
    }
  }

  .ant-btn {
    height: 36px;
    font-weight: 600;
  }

  .modal-body {
    margin: 0;

    .ant-btn {
      justify-content: center;
    }

    .ant-btn-default {
      border-color: $blackOpacity25;

      &:hover {
        border-color: $secondaryHover;
      }
    }
  }
}
