.bonus-distribution-container {
  display: flex;
  height: 100%;
  .ant-row {
    .ant-input-group-addon {
      border-radius: 6px 0 0 6px;
      font-weight: 600;
    }
  }

  .bonuses-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;

    .partner-code-selector {
      .ant-select-selector {
        border-radius: 6px;
      }
    }

    .images-container {
      margin: 16px 0 20px;
    }

    .time-scheduler {
      .date-box {
        margin-bottom: 20px;
      }
    }

    .customers-bulk-upload {
      margin-bottom: 20px;
      .label-text {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .info-message {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: $blackOpacity70;
        margin-top: 10px;
      }
    }

    .description-content {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .checkbox-slider {
    margin-bottom: 20px;
  }

  .ant-form-item > .ant-row {
    display: block;
  }

  .fields-block {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      width: 48%;
    }
    &.full-width {
      .ant-form-item {
        width: 100%;
      }
    }
    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .bonus-method-amount-assignment {
    margin-bottom: 20px;
    .description-content {
      display: flex;
      justify-content: space-between;
      .ant-form-item {
        margin-bottom: unset;
        width: 48%;
      }
    }
  }
}
