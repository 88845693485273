.ant-form-item {
  display: block;

  &.ant-form-item-has-feedback {
    .ant-form-item-control
      .ant-form-item-control-input-content
      .ant-form-item-control-input
      ~ div {
      display: none !important;
    }
  }

  &-label {
    font-weight: bold;
    label:after {
      content: unset;
    }
  }
}
