.map-field-container {
  .map-field-label {
    font-weight: bold;
  }
  .field-error {
    .ant-select-selector {
      border: 1px solid $red;
    }
  }
}
