.head-section {
  display: flex;
  justify-content: space-between;
  &__section-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  .btn-group {
    .ant-dropdown {
      &-trigger {
        &.btn-dots-remove {
          justify-content: center;
          margin: 0 10px;
          border: none;
          box-shadow: none;
        }
        &.ant-dropdown-open {
          justify-content: center;
          background-color: $secondary10;
          svg > path {
            fill: $secondary;
          }
        }
      }
      &-menu {
        .dropdown__nav-menu {
          .ant-dropdown-menu-item:last-child {
            border-top: none;
          }
        }
      }
    }
  }
  .ant-form-item {
    display: block;
  }
  &__secition-name {
    display: inline-block;
    font-weight: bold;
  }
}
