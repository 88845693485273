.quiz-container {
  display: flex;
  height: 100%;

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }
}

.quiz-configuration-section {
  width: 100%;
  padding: 100px 12% 50px;
  margin: 0 auto 5%;
  box-sizing: content-box;
  .fields-block {
    .ant-row {
      width: 48%;
    }
  }
  .ant-form-item {
    & > .ant-row {
      display: block;
    }
  }
  .ant-row {
    .ant-input-group-addon {
      border-radius: 6px 0 0 6px;
    }
    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .quiz-slug-field {
    margin-top: 40px;
  }

  .text-editor-container {
    margin-bottom: 16px;
  }
}

.quizzes-statistic-container {
  .quizzes-header-block {
    margin-bottom: 20px;

    .btn-action {
      height: 36px;
      background-color: $grey10;
      color: $black;
      font-weight: 600;
      margin: 15px 0 0;
      border-color: transparent;
      svg {
        margin-right: 5px;
        path {
          transition: all 0.3s $bezierTransition;
        }
      }

      &:not([disabled]):hover {
        background-color: $secondary10;
        color: $secondary;
        border-color: transparent;

        svg {
          path {
            fill: $secondary;

            &:first-child {
              stroke: $secondary;
            }
          }
        }
      }

      &.generate-stat {
        margin-right: 10px;
        .ant-spin {
          display: flex;
          margin: auto 10px auto 0;
        }
        &:not([disabled]):hover {
          svg path:first-child {
            stroke: none;
          }
        }
      }
    }
  }
  &__question-answer {
    padding: 5px 10px;
    font-size: 12px;

    &:first-child {
      padding: 5px 10px 0 10px;
    }
    &:last-child {
      padding: 0 10px 5px 10px;
    }
  }
}
