.bonus-request-container {
  margin-right: 16px;

  .bonus-request-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 18px;

    .left-filter-box {
      display: flex;
      align-items: center;

      &__search-wrapper {
        width: 100%;
        max-width: 272px;
      }
    }

    &__date-picker-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;

      .ant-picker {
        padding: 8px 10px;
        border-radius: 6px;
      }
    }
  }

  .table {
    &__bonus-request-list {
      .ant-table-cell {
        a {
          color: initial;
        }
      }
      .simple-field {
        &__date,
        &__time {
          display: inline-block;
        }
        &__time {
          color: $grey;
        }
        &.field-link:hover {
          cursor: pointer;
          color: $secondary;
          display: flex;
          padding: 9px 20px 9px 23px;
          gap: 6px;
          align-items: center;
          width: 100%;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &:after {
            content: "";
            height: 16px;
            width: 16px;
            background-repeat: no-repeat;
            background-image: url("../../images/icons/elements/open-link-icon.svg");
          }
        }
      }
      .comment-edit__wrapper {
        justify-content: center;
        .comment-edit {
          line-height: 0;
        }
      }
    }
  }


  @include table-height-100;

  .ant-table-body {
    min-height: calc(100% - 41px);
  }
}