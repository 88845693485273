.malicious-container {

  .malicious-header-block {
    padding: 0 0 18px;

    &__search-wrapper {
      width: 100%;
      max-width: 272px;
    }
  }
}
