.badge-field {
  padding: 8px 20px 8px 0;
  display: flex;
  align-items: center;
  gap: 15px;
  .badge-label {
    display: inline-block;
    white-space: nowrap;
  }
  .ant-select {
    &-arrow {
      svg {
        position: absolute;
        top: -4px;
        left: -13.3px;
      }
    }
  }
  .ant-picker,
  .ant-select {
    display: inline-block;
    border-radius: 6px;
    width: 100%;
  }
  .date-picker-select {
    position: relative;
    width: 100%;
    .btn-initial-field {
      position: absolute;
      right: -20px;
      top: 11px;
      visibility: hidden;
      justify-content: center;
      box-shadow: none;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &:hover {
    .btn-initial-field {
      cursor: pointer;
      visibility: initial;
    }
  }
}
