.layout {
  background: $white;

  &.unauthorized {
    height: 100%;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .site-layout-background {
      width: 100%;
      height: auto;
    }
  }

  .ant-page-header {
    position: absolute;
    width: calc(100% - 256px);
    right: 0;
    z-index: 10;

    @media (max-width: 992px) {
      width: 100%;
    }

      .ant-breadcrumb {
        font-family: $fontPrimary;
        position: absolute;
        top: 26px;
        left: 55px;
        a {
          padding: 0;
          border-radius: 0;
          height: inherit;
          margin-inline: 0;
          &:hover {
            background-color: transparent;
          }
        }
        .ant-breadcrumb-separator {
          margin-inline: 15px;
        }
          .crumbs-label {
            color: $black;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
          .crumbs-link {
            color: $blackOpacity70;
            font-size: 12px;
            font-style: normal;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;
          }
      }
  }

  .page-header-gap {
    width: 100%;
    height: 60px;
  }

  .content-container {
    margin: 24px 0 0 16px;
  }

  .section-container {
    margin: 0 auto;
    top: -96px;
    position: relative;
    height: calc(100vh - 138px);
    overflow-y: auto;
    box-sizing: content-box;
  }

  .site-layout-background {
    height: 100%;
  }

  footer {
    text-align: center;
    color: $blackOpacity70;
    background: transparent;
  }
}


.ant-layout-sider.ant-layout-sider-light.sidebar {
  background: $main;
}
