.ant-tabs {
  overflow: hidden;
  &-nav {
    &-wrap {
      .ant-tabs-tab {
        padding: 12px 10px 12px 6px;
      }
    }
  }
  &-dropdown-menu-item span {
    gap: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.country-container {
  &__tabs {
    .ant-tabs-nav {
      height: 36px;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            opacity: 1;
            width: 100%;
            margin-left: 0;
            padding: 0;
            .ant-tabs-tab-btn {
              padding-right: 0;
              font-weight: 400 !important;
              .ellipsis-country-name {
                height: 36px;
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 5px 10px;
                .flag-iso {
                  margin-bottom: 7px;
                }
                &:hover .tooltip-text {
                  display: block;
                }
                .tooltip-text {
                  display: none;
                  position: absolute;
                  top: -10px;
                  z-index: 1000;
                }
              }
            }
            &-active {
              .ant-tabs-tab-btn {
                font-weight: 400 !important;
                padding-right: 0;
              }
            }
            .ant-tabs-tab-btn {
              span:first-child {
                line-height: 0;
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
}
