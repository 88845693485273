.promotions-container {
  display: flex;
  height: 100%;

  .promotion-platform-select {
    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .promotions-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;

    .promotion-bonus-plan-select {
      .ant-select-selector {
        border-radius: 6px;
      }
    }
  }

  .fields-block {
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      .ant-form-item {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }
  }

  .fields-block,
  .checkbox-slider-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item,
    .checkbox-slider {
      width: 48%;
    }

    .ant-form-item>.ant-row,
    .checkbox-slider {
      display: block;
    }
  }

  .images-container {
    margin-bottom: 20px;
  }
}