@mixin table-height-100 {
  .table,
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-table {
    height: 100%;
  }

  &,
  .ant-spin-container,
  .ant-table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
