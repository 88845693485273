.entities-selection {
  &__select-all-wrapper {
    margin-bottom: 16px;
  }
  .header-section {
    display: grid;
    grid-template-columns: 36% 32% 32%;
    align-items: center;
    margin-bottom: 16px;
    padding-top: 8px;
  }
  &__brands-active {
    width: 68%;
    gap: 0;
    .entity-name-section {
      width: 53%;
    }
    .entity-checkbox-section {
      width: 47%;
      text-align: center;
    }
  }
  &__brands-available {
    width: 32%;
    justify-content: center;
  }
  .entity-name-section,
  .entity-checkbox-section {
    &__title {
      color: $black;
      font-weight: 600;
      text-align: center;
      
      &:first-child {
        text-align: left;
      }
    }
    &__label,
    &__checkbox-block {
      display: block;
      margin-top: 18px;
      height: 22px;

      &:first-child {
        margin-top: 0;
      }
    }
    &__label {
      &--active {
        color: $secondary;
      }
    }
    &__checkbox-block {
      display: flex;
      justify-content: center;
      text-align: center;
      .ant-checkbox > div {
        display: none;
      }
      &.ant-checkbox-wrapper-disabled {
        cursor: default;
      }

      &:hover {
        .ant-checkbox:after {
          visibility: hidden;
        }
      }
    }
  }
  &__entity-container {
    .header-section {
      display: grid;
      grid-template-columns: 50% 25% 25%;
      &__select-all {
        align-self: center;
      }
      &__title {
        text-align: center;
        font-weight: 600;
        .next-line {
          display: block;
        }
      }
    }
    .entity-checkbox-section {
      &:first-child {
        .ant-checkbox-group .ant-checkbox-wrapper.checkbox-block {
          padding: 7px 6px 6px 6px;
        }
      }
      .checkbox-block {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        padding: 5px;
        border-radius: 6px;

        .entity-icon {
          display: inline-flex;
          &.with-no-img {
            margin-left: 15px;
          }
        }


        .active-checkbox,
        .available-checkbox {
          position: relative;
          text-align: center;
          margin: 0;
          .ant-checkbox-wrapper {
            margin: 5px 0;
          }
          .entity-changed-on-brand {
            position: absolute;
            top: 6px;
            left: 0;
          }
        }

        &:hover {
          background: $secondary10;
        }
      }
    }
  }
}
