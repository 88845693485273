.text-editor-container {
  border-radius: 6px;
  margin-top: 16px;

  .text-editor {
    &-label {
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      height: 32px;
      font-size: 14px;
      color: $blackOpacity85;
      &__extra-grey {
        color: $grey;
      }
    }
  }

  .jodit-container {
    &,
    &:not(.jodit_inline) {
      .jodit-workplace {
        .jodit-wysiwyg,
        .jodit-source {
          height: 200px;
        }
      }
    }
  }

  & + & {
    margin-top: 24px;
  }
}

.jodit-container {
  &,
  &:not(.jodit_inline) {
    background: $white;
    border: 1px solid rgba(75, 84, 124, 0.25);
    box-sizing: border-box;
    border-radius: 6px;

    .jodit-toolbar-editor-collection_size_middle {
      background-color: #fff;
    }

    .jodit-workplace {
      background-color: #fff;

      .jodit-source {
        background: transparent;

        * {
          font: 14px / normal Monaco, Menlo, Ubuntu Mono, Consolas,
            source-code-pro, monospace;
        }
      }
    }

    .jodit-toolbar__box:not(:empty) {
      overflow: visible;
    }

    .jodit-toolbar-button {
      position: relative;
      overflow: visible;

      button {
      }

      &__button {
        min-width: 36px;
        height: 36px;
        color: $blackOpacity85;

        &[aria-label]:before {
          margin-top: 3px;
          border: 5px solid transparent;
          border-bottom-color: #21242a;
          content: "";
        }

        &[aria-label]:after {
          margin-top: 12px;
          padding: 5px;
          background-color: #21242a;
          border-radius: 2px;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
          color: #fff;
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          content: attr(aria-label);
        }

        &[aria-label]:before,
        &[aria-label]:after {
          position: absolute;
          z-index: 10;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
        }

        &:not([disabled]):hover {
          &[aria-label]:before,
          &[aria-label]:after {
            opacity: 1;
            transform: translate(-50%, 2px);
          }
        }
      }

      .jodit-icon {
        height: 16px;
        width: 20px;
      }
    }
  }
}

.jodit-icon {
  font-family: "IcoMoon", sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &:before {
    content: "";
    display: block;
    font-size: 16px;
  }

  &_undo:before {
    content: "\e967";
  }

  &_redo:before {
    content: "\e968";
  }

  &_paragraph:before {
    content: "\ea73";
  }

  &_bold:before {
    content: "\ea62";
  }

  &_italic:before {
    content: "\ea64";
  }

  &_underline:before {
    content: "\ea63";
  }

  &_left:before {
    content: "\ea77";
  }

  &_center:before {
    content: "\ea78";
  }

  &_right:before {
    content: "\ea79";
  }
  &_justify:before {
    content: "\ea7a";
  }

  &_ul:before {
    content: "\e9ba";
  }

  &_ol:before {
    content: "\e9b9";
  }

  &_eraser:before {
    content: "\ea6f";
  }

  &_link:before {
    content: "\e9cb";
  }

  &_source:before {
    content: "\ea80";
  }

  &_expand:before {
    content: "\e98b";
  }
}

.jodit-ui-group__expand {
  .jodit-icon:before {
    content: "\e98b";
  }
}

.jodit-add-new-line {
  display: none !important;
}
