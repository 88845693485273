.popover-editor {
  .ant-popover-title {
    width: 324px;
    padding: 16px 16px 8px;
    border: none;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-sub {
        font-weight: 400;
        margin-top: 10px;
      }
    }

    &__close-btn {
      width: 22px;
      height: 22px;
      background: none;
      cursor: pointer;
      justify-content: center;
      border: none;
    }
  }

  .ant-popover-inner-content {
    padding: 8px 16px 15px;

    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  &__textarea.ant-input {
    resize: none;
    box-shadow: 0 0 3px 0 $secondary;

    .ant-form-item & {
      height: 104px;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  &__apply-btn,
  &__close-btn {
    display: flex;
    justify-content: center;
    width: 32px;
    border: 1px solid $borderColor;
  }
  &__apply-btn {
    margin-left: auto;
    margin-right: 8px;
    background: $secondary;
    &:hover {
      background: rgba($secondary, .8);
    }
  }
  &__close-btn {
    background: transparent;
  }
  &__danger-text {
    color: $statusError;
    cursor: pointer;
  }
  &__editable-field {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 8px 22px;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: color .2s ease-in;

    &:hover {
      border: 1px solid $secondaryHover;
    }

    &.active {
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
    }

    &.ant-popover-open {
      padding: 6px 20px;
      border: 3px solid rgba($secondary, .15);
      color: $secondary;

      > span {
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-offset: 3px;
      }
    }
  }
}
