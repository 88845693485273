.auth-container {
  max-width: 348px;
  margin: 0 auto;
  text-align: center;
  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img.hidden {
      display: none;
    }

    &__hub {
      font-size: 32px;
      font-weight: bold;
    }
    .hub-logo,
    .hub-logo-loader {
      width: 50px;
      height: 50px;
    }
  }
  .greeting-message-block {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
    color: $black;
    display: block;
  }
  .sub-content-block {
    display: block;
    font-size: 16px;
    color: $blackOpacity70;
  }
  .ant-btn-primary {
    font-weight: 500;
    border: unset;
    border-radius: 6px;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    color: $white;
    background: $primary;
    margin-bottom: 20px;
    &:disabled {
      color: $disabledColor;
      background: $btnDisabled;
    }
    &:hover {
      background: $primary50;
    }
  }
  a.auth-wrapper-action {
    color: $black;
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .login-form-container,
  .forgot-password-form-container {
    margin-top: 20px;
  }

  .login-form-container {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-item-with-help {
      margin-bottom: 0;
    }
  }

  .ant-form-item-explain {
    min-height: 16px;
    font-size: 10px;
  }
}
