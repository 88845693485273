.filter-input {
  max-width: 200px;
  display: inline-block;
  border-radius: 6px;

  &.hide-search-btn {
    .ant-input-group-addon {
      display: none;
    }

    .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: 6px;
    }
  }

  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &__search {
    padding: 6px 14px;
    border-radius: 6px;

    .ant-input {
      color: $grey50;
    }

    &.without-border {
      border: none;
    }

    .ant-input-prefix {
      margin-right: 14px;
    }
  }

  &__search-image {
    color: $grey50;
    font-size: 18px;
  }
}