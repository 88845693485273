.upload-container {
  .ant-upload {
    border-radius: 6px;
    svg > path {
      fill: $blackOpacity65;
    }
    &.ant-upload-drag p.ant-upload-text {
      font-size: 14px;
    }
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    &-drag:hover {
      svg > path {
        transition: fill .3s;
        fill: $secondary;
      }
    }
  }
  .uploaded-img-block {
    border: 1px solid $borderColor;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .uploaded-img {
      max-width: 120px;
    }
    .preview-section {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 15px;
      .ant-modal-body {
        #video-preview {
          width: 100%;
        }
      }
      .video-preview-container {
        position: relative;
        display: flex;
        #video-preview {
          width: 100%;
          max-width: 120px;
        }
        .video-preview-info {
          cursor: pointer;
          opacity: 0;
          position: absolute;
          transition: opacity .2s;
          width: 100%;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          color: $white;
          background: $blackOpacity50;
        }
        &:hover {
          .video-preview-info {
            opacity: 1;
          }
        }
      }
      .img-params-block {
        padding-right: 5px;
        overflow: hidden;
        .img-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $secondary;
        }
        span {
          display: block;
        }
        .img-resolution {
          span {
            display: inline;
          }
          &:before {
            content: "•";
            margin: 0 5px;
          }
        }
        .img-size, .img-resolution {
          display: inline;
          color: $grey50;
          font-size: 14px;
        }
      }
    }
    .upload-action-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
      gap: 15px;
      .delete-btn {
        box-shadow: unset;
        &:hover {
          border-color: $statusErrorHover;
          background: $messageError;
        }
      }
      .change-btn {
        &:hover {
          svg path {
            fill: $secondary;
          }
        }
      }
    }
  }
}
