.copyable-field {
  position: relative;
  .ant-input {
    padding-right: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: unset;
    &-group-addon {
      font-weight: 600;
      border-radius: 6px 0 0 6px;
    }
  }
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 11px;
    right: 12px;
    &:hover {
      svg path {
        transition: stroke .5s;
        stroke: $secondary;
      }
    }
  }
}
