.ant-checkbox {
  &-group {
    .ant-checkbox-wrapper {
      margin: 5px 8px 5px 0;
      &.checkbox-block {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 6px;
        &:hover {
          background: $secondary10;
        }
      }
      &-checked {
        span {
          color: $secondary;
        }
      }
    }
  }
}