.roles-container {
  height: 100%;
  display: flex;

  .role-operation-section {
    width: 100%;
    padding: 100px 12% 50px;
    .ant-form-item {
      display: block;
      &-control-input-content {
        input {
          max-width: 273px;
        }
      }
      &-row {
        display: block;
      }
    }
    .permission-list {
      &__title {
        display: block;
        margin-bottom: 16px;
        font-weight: bold;
      }
      &__groups {
        .permission-group {
          &__title {
            display: block;
            text-transform: uppercase;
            color: $blackOpacity70;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .ant-tree {
            display: inline-block;
          }
          &:last-child {
            .ant-divider {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}
