.search-result-field {
  z-index: 2;
  position: absolute;
  background-color: $white;
  width: 100%;
  &.field-active {
    width: calc(100% + 20px);
    margin: -12px -10px 0 -10px;
    border-radius: 6px;
    padding: 12px 10px;
    box-shadow: 0 0 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  }
  &__wrap {
    position: relative;
    height: 32px;
  }
  .filter-input {
    width: 100%;
    max-width: unset;
  }
  &__box {
    &-label {
      display: block;
      margin: 14px 0 7px 0;
      color: $blackOpacity70;
    }
    &-list {
      overflow: auto;
      max-height: 100px;
      .search-item {
        padding: 5px 0;
        &:hover {
          cursor: pointer;
        }
      }
      .ant-empty {
        display: inline;
      }
    }
  }
}