.action-logs {
  height: 100%;
  padding: 0 16px;

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header-block {
    margin-bottom: 16px;
  }

  &-filter {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ant-divider {
      margin: 8px 0 0;
    }
  }

  &-filter-selects {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-filter-selects-container {
    display: flex;
    align-items: center;
  }

  &-filter-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .ant-tag {
      margin-right: 0;
    }
  }

  &-select-wrapper {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-datepicker {
    padding: 8px 10px;
    border-radius: 6px;

    .ant-picker-input {
      width: 90px;
    }
  }

  .datepicker-label {
    padding: 0 10px;
  }

  &-search-input {
    min-width: 110px;
    max-width: 220px;
    margin-right: 16px;
    padding: 8px 11px;
  }

  &-section {
    height: 100%;

    .table,
    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-table {
      height: 100%;
    }

    .ant-spin-container,
    .ant-table-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ant-table-body {
      min-height: 90%;
    }

    .ant-table-thead > tr > th {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .secondary-cell-text {
      color: $grey;
    }

    .ant-table-tbody {
      tr > td {
        padding: 13px 16px;
      }

      .log-transition-text {
        position: relative;
        border: 1px solid transparent;
        border-bottom-color: $divider;

        &:hover {
          border-radius: 6px;
          border-color: $secondaryHover;
          transition: border-radius ease-in 0s;

          .expand-btn {
            visibility: visible;
          }
        }
      }

      .expand-btn {
        position: absolute;
        right: 5px;
        top: 0;
        background-color: $hoverTableRow;
        visibility: hidden;
        transition: none;
      }

      pre {
        font-family: $fontPrimary;
      }
    }
  }

  .action-logs__create-text,
  .action-logs__update-box,
  .action-logs__delete-box {
    max-height: 84px;
    overflow: auto;

    pre {
      overflow: hidden;
      width: fit-content;
      margin: 0;
      font-family: $fontPrimary;
    }
  }

  .action-logs__create-box {
    &--raw-single {
      pre {
        white-space: initial;
      }
    }
  }
}
