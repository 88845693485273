.news-container {
  display: flex;
  height: 100%;

  .news-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;
    margin: 0 auto 5%;
    box-sizing: content-box;
  }

  .news-section-label {
    margin: 20px 0 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .fields-block {
    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
        font-weight: 600;
      }
    }

    &.full-width {
      .ant-form-item {
        width: 100%;
      }
    }

    &.no-label {
      .ant-form-item {
        align-self: flex-end;
      }
    }

    .ant-select-selector {
      border-radius: 6px;
    }
  }

  .fields-block {
    display: flex;
    justify-content: space-between;

    .ant-form-item,
    .checkbox-slider {
      width: 48%;
    }

    .ant-row,
    .checkbox-slider {
      display: block;
    }
  }

  &__tabs {
    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }
}