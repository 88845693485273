.ip-blocker-form {
  .ant-form-item {
    .checkbox-slider {
      display: flex;

      &-reverse {
        .ant-space {
          gap: 4px;

          &-item {
            font-weight: 600;
          }
        }

        .ant-switch {
          order: 1;
        }
      }

      &__label {
        white-space: nowrap;
        font-weight: 400;
      }
    }
  }

  .ant-collapse {
    .ant-form-item {
      &-label {
        label {
          white-space: normal;

          &:after {
            content: "";
          }
        }
      }
    }
  }

}
