.ant-input {
  padding-top: 8px;
  padding-bottom: 8px;
  &-affix-wrapper {
    &.ant-input-password {
      max-width: 357px;
      border-radius: 6px;
      padding: 4px;

      .ant-input-suffix {
        position: absolute;
        top: 13px;
        right: 8px;
      }

      input {
        border-radius: 6px;
        padding: 4px 11px;
      }
    }
  }
  &:not(input[type="password"]) {
    border-radius: 6px;
  }
}


.ant-form-item-control-input-content {
  .ant-select-single {
    .ant-select-selector {
      height: 40px;
      align-items: center;
    }
  }
}
