.gateways-template {
  display: flex;
  height: 100%;
}

.gateways-configuration {
  width: 100%;
  padding: 100px 12% 50px;
  margin: 0 auto 5%;

  .fields-block {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      width: 48%;
    }
  }

  .ant-form-item-row {
    display: block;
  }

  .linked-services {
    align-items: center;
    background-color: $main;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 24px;
    padding: 12px 20px;
  }
}
