.save-btn {
  &-sticky {
    position: sticky;
    bottom: -50px;
    z-index: 1;
    width: 100%;
    padding: 20px;
    background: $whiteOpacity85;
    border-top: 1px solid $borderColor;
    &__group {
      display: flex;
      gap: 10px;
      padding: 20px 0;
    }
    .btn:disabled {
      opacity: 1;
      background: $disabledBgDefault;
    }
  }
}
