.dashboard-wrapper {
  height: calc(100vh + 84px);
  overflow-y: auto;
  position: relative;
  padding: 80px 0 100px;
  display: block;
  top: -84px;
  .dashboard-container {
    margin: 0 auto;
    max-width: 538px;

    .greeting-message {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
    }
    .cta-message {
      display: block;
      margin-top: 10px;
      font-weight: normal;
      font-size: 16px;
      color: $blackOpacity70;
    }

    .brands-list-section {
      margin-top: 35px;
      display: grid;
      grid-template-columns: 268px 268px;

      .create-new-brand-ticket {
        margin: 16px 16px 0 0;
        min-height: 139px;
        background-color: $grey10;
        border-radius: 12px;
        justify-content: center;

        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
        }

        .add-brand-icon {
          transform: scale(1.3);
          box-shadow: none;
          border: none;
          background-color: $grey10;
        }

        &__text {
          color: $black;
          font-weight: 600;
        }
      }
      .brand-ticket,
      .global-configuration-ticket {
        position: relative;
        margin: 16px 16px 0 0;
        border-radius: 12px;
        min-height: 139px;
        color: $white;
        font-weight: 600;
        cursor: pointer;
        border: 1px solid $grey20;

        .brand-name {
          .ant-typography-ellipsis {
            display: inline-block;
            width: 160px;
            color: unset;
          }
        }

        &__selected {
          color: $black;
          border: 1px solid $secondary;
          .open-brand-btn {
            display: none;
          }
        }
      }
      .global-configuration {
        &__label {
          color: $black;
        }
        &__icon {
          padding: 5px 10px;
          margin: 15px 10px 0 24px;
          border-radius: 6px;
          display: inline-block;
          font-weight: bold;
          background-color: $primary;
          svg {
            top: 1px;
            transform: scale(1.4);
            position: relative;
          }
        }
      }
      .brand-icon {
        padding: 5px 10px;
        margin: 15px 10px 0 24px;
        border-radius: 6px;
        display: inline-block;
        color: $white;
        font-weight: bold;
      }
    }
    .brands-list-section>:hover {
      transform: scale(1.1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    @media (min-width: 1500px) {
      max-width: 735px;
      .brands-list-section {
        grid-template-columns: 368px 368px;
        .brand-ticket {
          .brand-name {
            .ant-typography-ellipsis {
              width: 265px;
            }
          }
        }
        &>:hover {
          transform: scale(1.07);
        }
      }
    }
  }
}
