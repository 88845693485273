.gateway-item {
  display: flex;

  &__checkbox {
    margin-right: 12px;
    margin-top: 13px;
    align-self: flex-start;
  }

  &__body {
    width: 100%;
    color: $black;
  }

  &__name {
    display: block;
  }

  &__btn {
    align-self: center;
    margin-left: 24px;
  }
}
