.date-range-picker {
  &__title {
    color: $blackOpacity70;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__label {
    width: 80px;
    margin-right: 16px;
    text-align: right;
  }

  .ant-picker {
    padding: 8px 12px;
    border-radius: 6px;
  }
}
