.providers-container {
  display: flex;
  height: 100%;

  .providers-configuration-section {
    width: 100%;
    padding: 100px 12% 50px;

    .fields-block {
      display: flex;
      justify-content: space-between;

      .ant-row {
        display: block;
      }

      .ant-form-item {
        width: 48%;
      }
    }

    .ant-form-item>.ant-row {
      display: block;
    }

    .ant-row {
      .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
      }
    }

    .omega-platform-select {
      .ant-select-selector {
        border-radius: 6px;
      }
    }

    .demo-mode-container {
      margin-bottom: 20px;

      .checkbox-slider {
        gap: 7px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 5px;
      }
    }

    .country-list-select-tab {
      .ant-select {
        &-selector {
          border-radius: 6px;
        }
      }
    }

    .provider-img-label {
      font-size: 14px;
      margin: 20px 0 10px;
      font-weight: bold;
    }

    .game-list-container {
      .gap-top {
        margin-top: 10px;
      }

      .filter-input {
        width: 100%;
        max-width: unset;
      }

      .provider-game-list-nav {
        .provider-game-nav-link {
          display: block;
          width: 100%;
          text-align: left;
          margin: 5px 0;
          transition: unset;
          box-shadow: unset;

          .item-icon img {
            margin-left: 0;
          }

          &:hover {
            border-color: transparent;
            background-color: $secondary10;

            span {
              text-decoration: underline;
              text-decoration-color: $secondaryHover;
            }
          }
        }
      }
    }

    @import "../forms/content/entities-selection";
  }

  &__tabs {
    margin-top: 10px;

    .ant-tabs {
      &-tab {
        &-btn {
          display: flex;
          gap: 7px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .images-container {
    .image-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}