// font
$fontPrimary: "Inter", sans-serif;
$fontMonospaced: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

// main color palette
$main: #f2f4f7;

$primary: #ffa53a;
$primary50: #ffb35b;

$secondary: #1890ff;
$secondaryHover: #40a9ff;
$secondary50: #d7ebff;
$secondary10: #eefaff;

$green: #58bf6b;
$lightGreen: #d8f6df;

$white: #ffffff;
$red: #fe4648;
$whiteLightRedColor: #ffeeee;
$whiteOpacity85: rgba(255, 255, 255, 0.85);

$black: #000000;
$blackOpacity85: rgba(0, 0, 0, 0.85);
$blackOpacity65: rgba(0, 0, 0, 0.65);
$blackOpacity70: rgba(65, 68, 85, 0.7);
$blackOpacity50: rgba(0, 0, 0, 0.5);
$blackOpacity45: rgba(65, 68, 85, 0.45);
$blackOpacity35: rgba(61, 68, 101, 0.35);
$blackOpacity25: rgba(75, 84, 124, 0.25);
$blackOpacity1: rgba(24, 39, 75, 0.1);

$black10: #313131;
$black20: #414455;

$darkBlue80: #26333f;

$grey: #8b93a6;
$grey50: #8c99b9;
$grey20: #e2e5ea;
$grey10: #f2f4f7;

// additional color palette
$statusSuccess: #d7fbd6;
$statusError: #fe4648;
$statusErrorHover: rgba(#fe4648, 0.8);
$messageError: #ffdbd9;
$indicatorSuccess: #4cd164;
$indicatorError: #fe4648;

$hoverTableRow: #fafafa;

$removeColor: #ffeeee;

$divider: #f0f0f0;

$disabledBg: rgba(0, 0, 0, 0.04);
$disabledColor: rgba(0, 0, 0, 0.25);
$disabledBorder: rgba(0, 0, 0, 0.15);
$disabledBgDefault: #f5f5f5;

$sidebarBorderColor: #d5d9e2;

$btnDisabled: rgba(0, 0, 0, 0.04);

$borderColor: #e7ebef;
$borderColorSecondary: #d9d9d9;

$transparentColor: transparent;

$bezierTransition: cubic-bezier(0.645, 0.045, 0.355, 1);

$selectedMenuItemBgColor: #e6f7ff;

//screen size breakpoints
$XS: 320px;
$S: 480px;
$M: 812px; // ipone x landscape
$L: 1024px;
$XL: 1200px;
$XXL: 1920px;
