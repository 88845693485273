@mixin item-icon-offset {

  .ant-select-selection-item,
  .ant-select-item-option-content {
    .item-icon {
      margin-right: 10px;
    }
  }
}

.ant-select {
  @include item-icon-offset;

  &-dropdown {
    @include item-icon-offset;

    .ant-select-item-option-selected {
      font-weight: normal !important;
      color: $secondary !important;
    }

    .provider-select {
      &__img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }

    &.select-dropdown-wrapper {
      border-radius: 6px;

      .search-wrapper {
        padding: 5px 10px 0;
      }

      .search-input {
        padding: 6px 11px;
        border-radius: 6px;
      }

      .ant-input-prefix {
        margin-right: 12px;
      }

      .ant-select-item {
        padding: 9px 22px;
      }

      .ant-select-item-option-content {
        margin-left: 28px;
      }

      .ant-select-item-option-state {
        position: absolute;
        margin-right: 10px;
      }

      .ant-select-item-option-selected {
        color: $secondary;
        font-weight: 400;
        background-color: $white;
      }

      .has-divider {
        border-bottom: 1px solid $divider;
      }
    }
  }

  &-dropdown-custom {
    min-width: 109px;

    // @include item-icon-offset;

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      padding: 5px 35px 5px 10px;
      border-radius: 6px;
    }

    .ant-select-selection-overflow-item-suffix,
    .ant-select-selection-overflow-item-rest {
      display: none;
    }

    &.ant-select-show-arrow {
      .ant-select-selection-item {
        max-width: 100px;
        padding: 0;
      }
    }
  }

  .ant-select-arrow {
    right: 16px;
  }
}