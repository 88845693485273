html, body, #root {
  height: 100%;
  overflow-y: hidden;
}

body {
  margin: 0;
}

$scrollSize: 8px;

// Scrollbar styles for Chrome
::-webkit-scrollbar {
  height: $scrollSize;
  width: $scrollSize;
}

// Track
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px $blackOpacity35;
  border-radius: $scrollSize;
}

// Handle
::-webkit-scrollbar-thumb {
  border-radius: $scrollSize;
  background: $blackOpacity45;
}

.ant-tooltip-hidden {
    display: none;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: unset;
}

.ant-image {
  &-error {
    height: 80px;
    width: 80px;
  }
}
