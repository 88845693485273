.gateway-phone-code {
  display: flex;
  align-items: flex-start;

  &__label {
    margin-right: 12px;
    line-height: 32px;
  }

  &__select {
    width: 100%;
  }
}
