.table-modal {
  &__content {
    font-family: $fontPrimary;

    .ant-table {
      .ant-empty-normal {
        margin: 0;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    &-close {
      cursor: pointer;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    &-apply {
      display: flex;
      cursor: pointer;
      margin-top: 20px;

      &-disable {
        cursor: not-allowed;
      }
    }
  }
}
