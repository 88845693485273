.droppable-list-container {
  overflow-y: auto;
  overflow-x: hidden;
  &.save-btn-bottom {
    display: flex;
    flex-direction: column-reverse;
    .droppable-list__apply-btn {
      max-width: fit-content;
    }
  }
  .droppable-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    list-style-type: none;
    padding: 0;

    &-wrapper {
      height: 680px;
      .ReactVirtualized__Grid {
        padding: 14px 0 10px;
        overflow-x: hidden !important;
      }
      .droppable-list__droppable-item {
        margin: 0 0 8px;
      }
    }

    &__droppable-item {
      position: relative;
      margin: 4px 0 8px;
      overflow: initial !important;
      cursor: grabbing;
      height: auto;
      min-height: 46px;
      &.ant-menu-item {
        padding: 0 10px 0 0;
      }
      &.not-draggable {
        cursor: default;
      }
      .droppable-list__item-container {
        flex: 1;
      }
    }

    &__item-container {
      display: flex;
      flex: 1;
      min-height: 46px;
      line-height: 46px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__position {
      display: inline-block;
      width: 22px;
      margin: 0 12px 0 10px;
      color: $blackOpacity70;
      cursor: pointer;
    }

    &__icon.anticon {
      display: flex;
      align-items: center;
      height: 46px;
      margin-right: 15px;

      svg {
        vertical-align: -1px;
        fill: $grey50;
      }
    }

    &__title {
      flex-grow: 1;
      overflow: hidden;

      &--selected {
        font-weight: bold;
      }

      .ant-typography {
        margin: 0;
        line-height: 46px;
      }
    }

    &__component {
      width: 100%;
    }

    &__apply-btn {
      margin-bottom: 6px;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.droppable-list {
  &__dropdown {
    width: 100px;
    padding: 0;
    background: white;

    .ant-dropdown-menu {
      padding: inherit;
      min-width: unset;
    }

    &-search {
      .search-input {
        padding: 4px 8px;
        border-radius: 6px;
      }
    }

    &-list {
      height: 370px;
      padding-top: 5px;
    }

    &-item {
      padding: 0 4px;
      cursor: pointer;

      &.current-selected {
        background-color: $selectedMenuItemBgColor;
      }
    }
  }
}
