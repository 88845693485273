.section-description-container {
  margin-bottom: 16px;

  .ant-input {
    max-width: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    &::before {
      content: unset;
    }

    .ant-tabs-tab {
      opacity: 0.5;
      border-radius: 6px 6px 0 0;
      background: transparent;
      margin-right: 0;
      min-width: unset;
      width: 60px;
      font-size: 12px;
      align-items: center;
      justify-content: center;

      .ant-tabs-tab-btn {
        color: $darkBlue80;
        font-weight: 500 !important;
        padding-right: 5px;
      }

      &-active {
        background: $grey10;
        opacity: 1;
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  .ant-tabs-content-holder {
    background: $grey10;
    padding: 10px 24px 30px;
    border-radius: 0 6px 6px 6px;

    .text-editor-container {
      textarea {
        height: 0;
      }
    }
  }

  .description-content {
    .info-text-typography {
      margin: 10px 0;
    }

    .info-text-divider {
      margin: 5px 0 10px;
    }

    .additional-field-wrapper {
      position: relative;

      &--textarea {
        margin-top: 16px;

        .textarea-wrapper {
          position: relative;
        }

        .btn-group-switcher {
          width: 100%;
          margin-bottom: 10px;
        }

        .btn-switcher {
          width: 50%;
          justify-content: center;
        }
      }

      &.no-validate {
        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-form-item-margin-offset,
        .ant-form-item-control-input ~ div {
          display: none !important;
        }
      }

      .expand-btn {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    .ant-form-item {
      > .ant-row {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__select {
      .ant-select-selector {
        border-radius: 6px;
      }
    }
  }
}
