.ant-empty {
  &.empty-block {
    width: 100%;
    &__centred {
      transform: translateY(-50px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}