.entity-form-lists-container {
  .btn-group-switcher {
    width: 100%;
    .btn-switcher {
      width: 50%;
      justify-content: center;
    }
  }
  .provider-head-block {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    .back-btn {
      display: inline-flex;
      font-size: 14px;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: $secondary;
      }
    }
    .provider-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }
    .select-all-block {
      width: 85px;
    }
  }
  .info-text {
    color: $blackOpacity70;
    margin: 8px 0 14px;
    display: block;
  }

  .filter-input {
    width: 100%;
    max-width: unset;
    margin: 10px 0;
  }

  .games-checkbox-container {
    position: relative;
    @import "../../components/atoms/select-all-checkbox";
  }

  .provider-with-games {
    border-radius: 6px;
    margin-bottom: 10px;
    .ant-checkbox-wrapper {
      display: inline-block;
      margin: 0 15px 0 10px;
    }
    .provider-block {
      width: 100%;
      padding: 5px 10px;
      display: inline-flex;
      justify-content: space-between;
      .left-block {
        .item-icon {
          display: inline-block;
        }
        &.with-no-img {
          margin-left: 15px;
        }
      }
      .provider-img {
        max-width: 20px;
        max-height: 20px;
        margin: 0 15px 0 0;
        border-radius: 6px;
      }
      .right-block {
        display: inline-flex;
        align-items: center;
        gap: 15px;
        .arrow-right-icon {
          path {
            fill: $secondary;
          }
        }
        .selected-items-amount {
          color: $grey50;
        }
      }
    }
    &:hover {
      cursor: pointer;
      background: $secondary10;
      color: $secondary;
    }
  }
}
