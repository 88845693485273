body.modal-is-open {
  overflow: hidden;
}

.modal {
  padding: 15px 15px 0;

  &__content {
    max-width: 100%;
    outline: none;

    .ant-modal-content {
      border-radius: 6px;
    }
  }
}

.modal-content {
  position: relative;
  word-break: break-word;

  &--with-after-footer {
    padding-bottom: 0;
  }
}

.modal-header {

}

.modal-body {
  margin: 20px 0 0;
}

.modal-footer {
  display: block;
}

.modal-after-footer {
  position: relative;
  margin: 16px 0 0;
  padding: 4px 0 18px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.modal-title {
  width: 100%;
  margin: 0;
  color: $black;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

.modal-desc {
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: $blackOpacity85;
}

@mixin modal-custom($additionalClassName) {
  .modal.#{$additionalClassName} {
    @content;
  }
}

@include modal-custom("file-upload-modal") {
  .modal {
    &__content {
      overflow: visible;
    }
  }

  .ant-upload-drag {
    background: $white;

    .ant-upload {
      padding: 38px 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .file-uploader {
    &__container {
      width: 100%;
      max-width: 384px;
      margin: 0 auto;

      &.file-storage-uploader {
        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          margin-right: auto;
          .ant-checkbox + span {
            padding-top: 5px;
          }
        }
      }
    }
    &__icon {
      margin-bottom: 16px;
      color: $secondary;
      font-size: 48px;
      line-height: 0;
    }
    &__text {
      margin-bottom: 4px;
      color: $black;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
    }
    &__hint {
      color: $grey50;
      font-size: 14px;
      line-height: 22px;
    }
    &__btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    &__btn,
    &__btn-submit {
      height: auto;
      min-width: auto;
      padding: 6px 17px;
      font-weight: 600;
    }
    &__btn {
      margin-right: 16px;
      border: 1px solid $blackOpacity35;
      &:hover {
        border-color: $secondaryHover;
      }
    }
    &__input {
      padding: 8px 13px;
      border: 1px solid $grey10;
      border-radius: 6px;
      background: $grey10;
      &-icon {
        color: $grey50;
        font-size: 14px;
      }
      .ant-input-prefix {
        margin-right: 8px;
      }
      .ant-input {
        border-radius: 0;
        background: $grey10;
        color: $secondary;
        font-weight: 400;
      }
      .ant-input-clear-icon {
        color: $indicatorError;
        font-size: 14px;
      }
    }
  }
}
