.covery-form {
  .ant-form-item {
    margin-bottom: 0;
    .ant-input-number {
      border-radius: 6px;
      //&.field-script-caching {
      //  width: 180px;
      //}
    }
    &-explain {
      display: none;
    }
  }
  .customer-events-count {
    .checkbox-slider {
      transform: scale(.8);
    }
  }
  .copyable-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ant-form-item {
      width: 100%;
    }
  }
}
