.ant-dropdown {
  border: 1px solid $borderColor;
  border-radius: 6px;
  box-shadow: 0 10px 32px -4px $blackOpacity1;
  &-menu {
    min-width: 210px;
    border-radius: 6px;
    &-item {
      padding-left: 15px;
      &:hover {
        background-color: transparent;
      }
      a:hover {
        color: $secondary;
      }
    }
  }
  &.header-dropdown-menu__overlay {
    .dropdown__nav-menu {
      .ant-dropdown-menu-item {
        display: flex;
        flex: 1;
        border-radius: 0;
        .ant-menu-item {
          padding-left: 0;
          &:active {
            background: transparent;
          }
        }
        &:first-child {
          padding: 10px 15px;
          border-bottom: 1px solid $borderColor;
          cursor: default;
          background: inherit;
        }
        &:last-child {
          border-top: 1px solid $borderColor;
        }
      }
      .ant-dropdown-menu-item-icon {
        margin-right: 8px;
      }
    }
  }
  &.brand-select-dropdown__overlay {
    .ant-dropdown-menu {
      padding: 0 0 4px 0;
      .ellipsis-brand-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 165px;
      }
      .brand-icon {
        color: $white;
        font-size: 13px;
        padding: 2px 5px 2px;
        border-radius: 6px;
        display: inline-block;
        font-weight: bold;
        margin-right: 10px;
        svg {
          margin: -1px 1px;
        }
      }
      .ant-dropdown-menu-item {
        padding: 7px 12px;
      }
      .ant-dropdown-menu-item:hover {
        span:not(.brand-icon) {
          color: $secondary;
        }
      }
    }
    &.global-config {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item:last-child {
          border-top: 1px solid $borderColor;
        }
      }
    }
    &:not(&.global-config).several-brand-selected {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item:first-child {
          border-bottom: 1px solid $borderColor;
        }
      }
    }
  }
  &.remove-dropdown {
    z-index: 1;
    .ant-dropdown-menu-item {
      display: flex;
      gap: 10px;
      height: auto;
      line-height: normal;
      align-items: center;
      &-active {
        color: $secondary;
        background-color: transparent;
      }
    }
    .ant-menu-item-selected {
      background-color: transparent;
    }
  }
}
