.image-uploader {
  &__switcher {
    margin-bottom: 10px;
    &.btn-group-switcher {
      width: 100%;
    }

    .btn-switcher {
      width: 50%;
      justify-content: center;
    }
  }

  .image-section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__item {
      .upload-image-error {
        color: $statusError;
      }
    }
  }
}
